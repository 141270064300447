<template>
    <b-tabs justified class="non-printable">
        <b-tab title="Customer Activity">
            <CustomerActivityTab :account="account" ref="CustomerActivityTab"/>
        </b-tab>     
        <b-tab title="Payments & Adjustments">
            <PaymentsAdjustmentsTab :account="account" ref="PaymentsAdjustmentsTab"/>
        </b-tab>       
    </b-tabs>
</template>

<script>
import CustomerActivityTab from './CustomerActivityTab.vue';
import PaymentsAdjustmentsTab from './PaymentsAdjustmentsTab.vue';

export default {
    components: { CustomerActivityTab, PaymentsAdjustmentsTab}, 
    props: {
        customers: Object,
        account: Object
    }
};
</script>
