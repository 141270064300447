import { extend } from 'vee-validate';
import { min_value, max } from 'vee-validate/dist/rules';

export default {
    data() {
        extend('is-valid-ledgernumber', {
            params: ['isValidLedgerNumber', 'rowData'],
            message() {
                return 'Invalid ledger number conflict with an existing Chart of Account';
            },
            validate(value, { isValidLedgerNumber, rowData }) {
                return isValidLedgerNumber(value, rowData);
            },    
            computesRequired: true 
        }),
        extend('is-valid-type', {
            params: ['isValidType', 'rowData'],
            message() {
                return 'Please select a valid chart of accounts type.';
            },
            validate(value, { isValidType, rowData }) {
                return isValidType(value, rowData);
            },    
            computesRequired: true 
        }),
        extend('is-valid-category', {
            params: ['isValidCategory', 'rowData'],
            message() {
                return 'Please select a valid chart of accounts category.';
            },
            validate(value, { isValidCategory, rowData }) {
                return isValidCategory(value, rowData);
            },    
            computesRequired: true 
        });   

        return {
            chartOfAccountsConfiguration: {
                type: 'ChartOfAccounts',
                label: 'Accounting Chart of Accounts',
                sampleNotes: [                   
                ],
                fields: [
                    {
                        key: 'LedgerNumber',
                        label: 'Ledger Number',
                        hasErrorCalculator: (value, rowData) => {
                            return !this.isValidLedgerNumber(value, rowData);
                        },
                        rules: {
                            'is-valid-ledgernumber': {
                                isValidLedgerNumber: this
                                    .isValidLedgerNumber
                            },
                            max: { length: 255 }
                        },                        
                        addRowDataToRule: 'is-valid-ledgernumber',                        
                        samples: [
                            '"1000"',
                            '"2000"'
                        ]
                    },
                    {
                        key: 'Type',
                        hasErrorCalculator: (value, rowData) => {
                            return !this.isValidType(value, rowData);
                        },
                        rules: {
                            'is-valid-type': {
                                isValidType: this
                                    .isValidType
                            }
                        },                        
                        addRowDataToRule: 'is-valid-type',                        
                        samples: ['"Sales Tax"', '"Pay Out"'],
                        dataType: 'chartOfAccountsTypes'
                    },
                    {
                        key: 'Category',
                        hasErrorCalculator: (value, rowData) => {
                            return !this.isValidCategory(value, rowData);
                        },
                        rules: {
                            'is-valid-category': {
                                isValidCategory: this
                                    .isValidCategory
                            }
                        },                        
                        addRowDataToRule: 'is-valid-category',                           
                        samples: ['"Expenses"', '"Other Income"'],
                        dataType: 'chartOfAccountsCategoryDescriptions'
                    },                     
                    {
                        key: 'Description',
                        hasErrorCalculator: value => {
                            return !max.validate(value, { length: 255 });
                        },
                        rules: 'max:255',
                        samples: [
                            '"Note about this record"', 
                            '"Name changed 1/24/2024"'
                        ],
                    },                    
                ],
                canRunNoParse: true,
                cronJobTypeId: 1
            }
        };
    },
    methods: {
        isNewChartOfAccount(row) {
            var chartOfAccount = this.selectListOptions.chartOfAccounts.find(account => account.text.toLowerCase() === row.LedgerNumber.toLowerCase());

            return {
                isNew: chartOfAccount == undefined,
                chartOfAccountId: chartOfAccount?.value
            }
        },
        isDuplicateChartOfAccount(chartOfAccountId, row) {
            for (let option of this.selectListOptions.chartOfAccounts) {
                if(chartOfAccountId !== option.value && row.LedgerNumber?.toLowerCase() === option.text?.toLowerCase()) {
                    return true;
                }
            }

            return false;
        },
        isValidLedgerNumber(value, row) {
            const isEmpty = !value;
            const { isNew, chartOfAccountId } = this.isNewChartOfAccount(row);
            const isValidLength = max.validate(value, { length: 255 });

            if(this.isDuplicateChartOfAccount(chartOfAccountId, row)) {
                return false;
            }

            return !(isNew && isEmpty) && (isEmpty || isValidLength);
        },
        isValidType(value, row) {
            const isEmpty = value === '' || value === undefined;
            const { isNew } = this.isNewChartOfAccount(row);
            const isValid = min_value.validate(value, { min: 0 });

            return isNew 
                ? !isEmpty && isValid 
                : isEmpty || isValid;
        },        
        isValidCategory(value, row) {
            const isEmpty = value === '' || value === undefined;
            const { isNew } = this.isNewChartOfAccount(row);
            const isValid = min_value.validate(value, { min: 0 });

            return isNew 
                ? !isEmpty && isValid 
                : isEmpty || isValid;
        }
    }
};
