<template>
    <div>
        <p-modal
            size="lg"
            label="Invoice Adjustments"
            name="invoiceAdjustmentsModal"
            ref="invoiceAdjustmentsModal"
            @ok.prevent="saveAdjustments"            
            @cancel.prevent="closeModal"
            @close.prevent="closeModal"
            @show="showModal"
        >
            <template v-slot:default>
                <p-form 
                    ref="adjustmentsForm"
                >
                    <p-table
                        :fields="fields"
                        :items="adjustments"
                        :show-select-all="false"
                        :enableRowSelection="false"
                    >
                        <template
                            v-slot:cell(reason)="{ value, item }"
                        >
                            <p-select
                                :disabled="!canModify"
                                name="Reason"
                                :options="adjustmentReasons"
                                v-model="item.reason"
                                :rules="item.amount ? 'required' : ''"
                                @selectedObject="(option) => onSelectedObject(option, item)"
                            />
                        </template>
                        <template
                            v-slot:cell(ledgerNumber)="{value, item}"
                        >
                            <p-input
                                v-model="item.ledgerNumber"
                                :disabled="true"
                            />
                        </template>
                        <template 
                            v-slot:cell(amount)="{value, item}"
                        >
                            <p-number
                                name="Amount"
                                v-model="item.amount"
                                input-type="money"
                                :rules="item.reason ? 'required' : ''"
                                @change="isFormValid()"
                                :disabled="!canModify"
                            />
                        </template>
                        <template
                            v-slot:cell(removeAdjustment)="{
                                value,
                                item
                            }"
                        >
                            <b-button
                                class="p-1 ml-1 short-button"
                                :disabled="!canModify"
                            >
                                <BIconTrash
                                    icon="trash"
                                    @click="removeAdjustment(item)"
                                ></BIconTrash
                            ></b-button>
                        </template>
                    </p-table>
                </p-form>
            </template>
            <template v-slot:modal-footer="{ ok, cancel }">
                <b-row>
                    <b-col>
                        <b-button 
                            :is-busy="isBusy"
                            variant="outline-primary"
                            @click="cancel"
                        >{{ canModify ? 'Cancel' : 'Close' }}
                        </b-button>
                        <p-button
                            v-if="canModify"
                            :is-busy="isBusy" 
                            variant="primary" 
                            @click="ok" 
                            class="ml-2"
                        >Save
                        </p-button>
                    </b-col>
                </b-row>
            </template>
        </p-modal>
    </div>
</template>

<script>
import { currencyFormatter } from '../../components/Common/Formatters';
import selectListOptionsDataContext from '@/services/selectListOptions.dataContext.js';

export default {
    name: 'InvoiceAdjustmentsModal',
    props: {
        isBusy: {
            type: Boolean,
            default: false
        },
        adjustmentsData: {
            type: Array,
            default: () => []
        },
        canModify: {
            type: Boolean,
            default: true
        },
        locationId: {
            type: [Object, Number],
            default: 0
        }
    },
    data() {
        return {
            isDirty: false,
            isSaving: false,
            adjustments: [],
            adjustmentReasons: [],
            fields: [
                {
                    key: 'invoiceAdjustmentId',
                    hidden: true
                },
                {
                    key: 'reason',
                    label: 'Adjustment Reason'
                },
                {
                    key: 'ledgerNumber',
                    label: 'GL Code'
                },
                {
                    key: 'amount',
                    label: 'Adjustment Amount',
                    formatter: currencyFormatter
                },
                {
                    key: 'removeAdjustment',
                    label: ''
                }
            ]
        }
    },
    computed: {
        adjustmentsTotal() {
            let total = 0;
            this.adjustments.forEach(x => {
                total += x.Amount;
            });
            return total;
        },
        packedAdjustments() {
            return this.adjustments.filter(adjustment => adjustment.reason && adjustment.amount);
        }
    },
    methods: {
        async onSelectedObject(option, adjustment) {
            adjustment.ledgerNumber = option.ledgerNumber;
            adjustment.isDebit = option.isDebit;
            adjustment.locationId = this.locationId;
            await this.isFormValid();
        },
        async isFormValid() {
            return await this.$refs.adjustmentsForm.validate();
        },
        removeAdjustment(adjustment) {
            adjustment.invoiceAdjustmentId = null;
            adjustment.reason = null;
            adjustment.ledgerNumber = null;
            adjustment.amount = null;
            adjustment.isDebit = null;
            adjustment.locationId = null;
        },
        async saveAdjustments() {
            if (!(await this.isFormValid())) {
                this.isSaving = false;
                return;
            }
            
            this.$emit('ok', this.packedAdjustments);

            this.padAdjustments(this.packedAdjustments);
            this.isDirty = false;
            this.isSaving = true;
            this.closeModal();
            this.isSaving = false;
        },
        closeModal() {
            if (this.isDirty && !this.isSaving) {
                const h = this.$createElement;
                this.$bvModal
                    .msgBoxConfirm(h('div', [
                        h('p', ['Are you sure you want to leave without saving?',]),
                        h('p', ['Select ‘OK’ to discard changes or ‘Cancel’ to return to editing.'])
                        ])
                    )
                    .then(value => {
                        if (!value) {
                            this.$refs.invoiceAdjustmentsModal.show();
                            return;
                        } else {
                            this.$refs.invoiceAdjustmentsModal.hide();
                            this.padAdjustments(this.adjustmentsData);
                        }
                    });
            } else {
                this.$nextTick(() => {
                    this.$refs.invoiceAdjustmentsModal.hide();
                });
            }
        },
        padAdjustments(adjustments) {
            let padCount = 5 - (adjustments?.length ?? 0);
            let padArray = [];
            for(let i = 0; i < padCount; i++) {
                padArray.push({
                    invoiceAdjustmentId: null,
                    reason: null,
                    ledgerNumber: null,
                    amount: null,
                    isDebit: null,
                    locationId: null
                });
            }
            this.adjustments = [...adjustments ?? [], ...padArray];
            this.isDirty = false;
        },
        async loadData() {
            this.padAdjustments(this.adjustmentsData);

        },
        showModal() {
            this.isDirty = false;
            this.isSaving = false;
        },
        async setAdjustmentReasons() {
            this.adjustmentReasons = (await selectListOptionsDataContext.getStoreDropdownData('invoiceAdjustmentReasons'))
            .filter(x => x.filter == this.locationId);
        }
    },
    watch: {
        adjustments: {
            handler: function() {
                this.isDirty = true;
            },
            deep: true
        },
        locationId: {
            handler: async function() {
                await this.setAdjustmentReasons();
            }
        }
    },
    mounted: async function() {
        await this.loadData();
        await this.setAdjustmentReasons();
    }
}
</script>