<template>
    <b-overlay
        :show="isBusy"
        rounded
        opacity="0.5"
        spinner-small
        spinner-variant="primary"
        class="d-inline-block"
    >
        <span>
            <b-row>
                <b-col class="mr-2">
                    Departments, Classes, Categories
                    <template v-if="treeSelection.length > 0">
                        ({{ treeSelection.length }})
                    </template>
                    <!-- <template v-if="treeSelection.length > 0">
                        <span class="float-right">
                            <b-button v-if="clearButton"
                                variant="light"
                                size="sm"
                                class="py-0"
                                @click="clearSelections"
                                >Clear
                                <b-icon-x ></b-icon-x
                            ></b-button>
                        </span>
                    </template> -->
                </b-col>
            </b-row>

            <p-tree
                :data="filteredData"
                :key="filteredData.length"
                expandable
                :include-search-filter="includeSearchFilter"
                :filter-place-holder="filterPlaceHolder"
                @selection-change="onTreeSelection"
            >
            </p-tree> </span
    ></b-overlay>
</template>

<script>
import axios from 'axios';

export default {
    name: 'DCCsSelector',
    props: {
        includeSearchFilter: {
            type: Boolean,
            default: true
        },
        clearButton: {
            type: Boolean,
            default: true
        },
    },

    data() {
        return {
            treeData: [],
            dccs: [],
            treeSelection: [],
            isBusy: false,
            filterPlaceHolder: "Search by department",
        };
    },

    computed: {
        filteredData() {
            return this.treeData;
        }
    },

    methods: {
        setTreeData() {
            //level 1 Department
            //level 2 Classes
            //level 3 Categories
            this.treeData = this.dccs.map(d => ({
                ...d,
                icon: 'fa fa-calendar',
                level: 'department',
                text: d.number + ' - ' + d.name,
                value: d.dccDepartmentId,
                children: d.dccClasses
                    .filter(c => d.dccDepartmentId === c.dccDepartmentId)
                    .map(c => ({
                        ...c,
                        icon: 'fa fa-layer-group',
                        level: 'class',
                        text: c.number + ' - ' + c.name,
                        value: c.dccClassId,
                        children: c.dccCategories
                            .filter(ca => c.dccClassId === ca.dccClassId)
                            .map(ca => ({
                                value: ca.dccId,
                                text: ca.number + ' - ' + ca.name,
                                dccClassId: c.dccClassId,
                                dccClassName: c.name,
                                dccDepartmentName: d.name,
                                dccDepartmentId: d.dccDepartmentId,
                                icon: 'fa fa-thumbtack',
                                level: 'category'
                            }))
                    }))
            }));

            if (
                this.dccs != null &&
                (this.treeData != null || this.treeData != [])
            ) {
                let selectedDepartment = this.treeData.find(
                    x => x.value == this.dccs.dccDepartmentId
                );
                if (selectedDepartment != null) {
                    selectedDepartment.selected = true;

                    let selectedClass = selectedDepartment.children.find(
                        y => y.value == this.dccs.dccClassId
                    );
                    if (selectedClass != null) {
                        selectedClass.selected = true;

                        let selectedCategory = selectedClass.dccCategories.find(
                            z => z.value == this.dccCategories.dccCategoryId
                        );
                        if (selectedCategory != null)
                            selectedCategory.selected = true;
                    }
                }

                this.onTreeSelection(this.filteredData);
            }
        },

        onTreeSelection(data) {
            this.treeSelection = data
                .flatMap(x => x.children)
                .flatMap(x => x.children)
                .filter(x => x.selected)
                .map(x => ({
                    dccId: x.value
                }));

            this.$emit('selection-change', this.treeSelection);
        },
        // clearSelections(){
        //     this.treeSelection = [];
        //     this.setTreeData();
        //     this.$emit('selection-change', this.treeSelection);
        // },
    },

    created: function() {
        this.dccs = [];
        this.isBusy = true;
        axios
            .get('dccs')
            .then(resp => {
                this.dccs = resp.data;
                this.setTreeData();
            })
            .finally(() => {
                this.isBusy = false;
            });
    }
};
</script>

<style scoped></style>
