<template>
    <p-form ref="form">
        <div v-if="product">
            <p-card title="">
                <b-row>
                    <p-feature-flag name="Product Images">
                        <b-col cols="12" lg="auto">
                            <b-row>
                                <b-col style="padding:10px 15px">
                                    <p-image
                                        ref="generalImage"
                                        v-model="
                                            productFamily.onlineProfileProperties
                                                .images[0]
                                        "
                                        @newURL="newPrimaryURL"
                                    />
                                </b-col>
                            </b-row>
                            <b-row style="padding:0 15px">
                                <b-col
                                    style="padding:0 5px 0 0"
                                    v-for="image in productFamily.onlineProfileProperties.images.filter(
                                        x => x.index != 0 && x.index < 4
                                    )"
                                    :key="image.index"
                                >
                                    <p-image
                                        ref='"image" + index'
                                        v-model="
                                            productFamily
                                                .onlineProfileProperties.images[
                                                image.index
                                            ]
                                        "
                                        @newURL="e => ImageURL(image.index, e)"
                                        small
                                    />
                                </b-col>
                            </b-row>
                        </b-col>
                    </p-feature-flag>

                    <b-col>
                        <b-row>
                            <b-col cols="12" lg="8">
                                <p-input
                                    label="Product Description"
                                    v-model="productFamily.description"
                                    rules="required|max:200"
                                    @change="SetDefaultValuesForOnlineProfile()"
                                />
                            </b-col>
                            <b-col cols="12" lg="4">
                                <p-select
                                    label="Product Type"
                                    :options="productTypes"
                                    v-model="productFamily.isMatrix"
                                    text-field="text"
                                    :disabled="!isNew"
                                    rules="required"
                                />
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12" sm="6" xl="2">
                                <p-number
                                    label="Selling Price"
                                    v-model="productInventory.sellingPrice"
                                    rules="required|min_value:0"
                                    input-type="money"
                                    left-align
                                >
                                    <template v-slot:append>
                                        <p-button
                                            type="button"
                                            @click="$bvModal.show('calculate')"
                                            v-b-modal.calculate
                                        >
                                            <i class="fas fa-calculator"></i>
                                        </p-button>
                                    </template>
                                </p-number>
                            </b-col>
                            <b-col cols="12" sm="6" xl="2">
                                <p-number
                                    input-type="percent"
                                    disabled="disabled"
                                    label="Margin"
                                    v-model.number="productInventory.margin"
                                    left-align
                                >
                                </p-number>
                            </b-col>
                            <b-col cols="12" sm="6" xl="2">
                                <p-number
                                    input-type="money"
                                    name="Cost"
                                    label="Cost"
                                    v-model.number="productInventory.cost"
                                    rules="required|min_value:0"
                                    @change="calculateMargin()"
                                    left-align
                                >
                                </p-number>
                            </b-col>

                            <b-col cols="12" lg="6">
                                <p-advanced-select
                                    label="DCC"
                                    data-type="dcc"
                                    @change="dccChanged"
                                    v-model.number="product.dccId"
                                    :filter="dccFilter"
                                    rules="required"
                                />
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12" lg="6">
                                <p-advanced-select
                                    label="Brand"
                                    data-type="brands"
                                    v-model="productFamily.productBrandId"
                                    add-empty-option
                                >
                                    <template v-slot:append>
                                        <b-button
                                            class="ml-0 px-1"
                                            style="white-space:nowrap;"
                                            v-b-modal.addBrand
                                            >Add New</b-button
                                        >
                                    </template>
                                </p-advanced-select>
                            </b-col>

                            <b-col cols="9" sm="4" xl="4">
                                <p-select
                                    data-type="priceTagTypes"
                                    label="Tag Type"
                                    v-model="product.priceTagTypeId"
                                />
                            </b-col>
                            <b-col
                                cols="3"
                                sm="2"
                                xl="2"
                                style="margin-top:30px"
                            >
                                <p-feature-flag name="Barcode Print">
                                    <b-button
                                            class=" px-2"
                                            style="white-space:nowrap;"
                                             variant="outline-primary"                
                                             @click="showPrintLabel"
                                            > Print Label
                                    </b-button >
                                    <PrintLabel
                                        v-if="
                                            product.priceTagTypeId &&
                                                product.defaultBarcode && product.productInventories[0].productInventoryId > 0
                                        "
                                        :product-family="productFamily"
                                        :product="product"
                                        :products="products"
                                        ref="printLabelButton"
                                    />
                                </p-feature-flag>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12" lg="6">
                                <p-input
                                    label="Product Notes"
                                    v-model="productFamily.comment"
                                    rules="max:200"
                                />
                            </b-col>

                            <b-col cols="12" lg="6">
                                <p-input
                                    label="Default Supplier"
                                    disabled="disabled"
                                    rules="max:200"
                                    :value="defaultSupplier"
                                >
                                </p-input>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12" lg="4">
                                <p-advanced-select
                                    v-if="!productFamily.isMatrix"
                                    label="Size"
                                    name="productDetailSize"
                                    data-type="productVariants"
                                    :filter="'1'"
                                    v-model="product.gmVariant1Id"
                                    add-empty-option
                                >
                                    <template v-slot:append>
                                        <b-button
                                            class="ml-0 px-1"
                                            style="white-space:nowrap;"
                                            v-b-modal.addSize
                                            >Add New</b-button
                                        >
                                    </template>
                                </p-advanced-select>
                                <p-advanced-select
                                    v-else
                                    label="Variant Group 1"
                                    data-type="productVariantGroups"
                                    v-model="productFamily.gmVariantGroup1Id"
                                    disabled
                                >
                                </p-advanced-select>
                            </b-col>
                            <b-col cols="12" lg="4">
                                <p-advanced-select
                                    v-if="!productFamily.isMatrix"
                                    label="Color"
                                    name="productDetailColor"
                                    data-type="productVariants"
                                    v-model="product.gmVariant2Id"
                                    :filter="'2'"
                                    add-empty-option
                                >
                                    <template v-slot:append>
                                        <b-button
                                            class="ml-0 px-1"
                                            style="white-space:nowrap;"
                                            v-b-modal.addColor
                                            >Add New</b-button
                                        >
                                    </template>
                                </p-advanced-select>
                                <p-advanced-select
                                    v-else
                                    label="Variant Group 2"
                                    data-type="productVariantGroups"
                                    v-model="productFamily.gmVariantGroup2Id"
                                    disabled
                                >
                                </p-advanced-select>
                            </b-col>
                            <b-col cols="12" lg="4" v-if="!productFamily.isMatrix">
                                <p-feature-flag ref="serializationFlag" name="Serialization">
                                    <p-card title="Serialize" style="margin-top: -10px; margin-bottom: 0px; margin-left: -18px;">
                                        <div style="display: flex; align-items: center;" >
                                            <p-checkbox
                                                :label="productFamily.isSerializationActive ? 'Enabled' : 'Disabled'"
                                                class="d-inline-block"
                                                style="margin-right: 4px;"
                                                :use-switch="true"
                                                v-model="productFamily.isSerializationActive"
                                                size="sm"
                                                @change="serializationToggleChange()"
                                            >
                                            </p-checkbox>                                   
                                            
                                            <div @click="depEligibleToggleChange()">
                                            <p-checkbox
                                                label="DEP Eligible"
                                                class="d-inline-block"
                                                style="margin-right: 1px;"
                                                :use-switch="true"
                                                v-model="productFamily.isDepEligible"
                                                :disabled="!productFamily.isSerializationActive"
                                                size="sm"                                            
                                            >
                                            </p-checkbox>
                                        </div>
                                            <b-button
                                                class="ml-2 px-1"
                                                style="white-space:nowrap; margin-top: -6px"
                                                variant="outline-primary"
                                                :disabled="!productFamily.isSerializationActive"
                                                @click="manageButtonClick()"
                                                > Manage Serials
                                            </b-button >
                                        </div>  
                                    </p-card>                                  
                                </p-feature-flag>
                            </b-col>                            
                        </b-row>
                    </b-col>
                </b-row>
            </p-card>

            <p-card title="SKU(s)">
                <b-button
                        class=" py-2 btn-add-variant ml-3"
                        v-if="productFamily.isMatrix"
                        style="white-space:nowrap;"
                            variant="outline-primary"                
                            @click="showAddVariantsModal()"
                        > <i class="pr-2 fas fa-edit"></i>Add Variants 
                </b-button >
                <p-table
                    ref="SKUTable"
                    :items="products"
                    :fields="fieldsSKU"
                    :enableRowSelection="false"
                    :per-page="500"
                    class="wide mt-2"
                    outlined
                >
                    <template #thead-top="data">
                        <b-tr class="text-center">
                        <b-th colspan="8"></b-th>
                        <b-th colspan="2" class="custom-border-left" >Current Location</b-th>
                        <b-th colspan="2" class="custom-border-left custom-border-right">All Locations</b-th>
                        </b-tr>
                    </template>
                    <template
                        v-slot:cell(productImageId)="{
                            value,
                            item
                        }"
                    >
                        <p-image
                            :ref='"image" + item.sku'
                            v-model="
                                item.productImage
                            "
                            @newURL="e => SKUImageURL(item, e)"
                            small
                            :key="item.sku"
                        />
                    </template>

                    <template
                        v-slot:cell(defaultBarcode)="{
                            value,
                            item
                        }"
                    >
                        <p-input
                            label=""
                            name="Default Barcode"
                            v-model="item.defaultBarcode"
                            :disabled="true"
                            class="pt-3"
                        >
                            <template v-slot:append>
                                <p-button class="pl-0" @click="openBarcodes(item)">
                                    More
                                </p-button>
                            </template>
                        </p-input>
                    </template>
                    <template
                        v-slot:cell(sellingPrice)="{
                            value,
                            item
                        }"
                    >
                        <p-number
                            input-type="money"
                            name="Selling Price"
                            label=""
                            rules="min_value:0"
                            v-model.number="item.productInventories[0].sellingPrice"
                            left-align
                            class="pt-3"
                        >
                            <template v-slot:append>
                                <p-button
                                    type="button"
                                    @click="updateProductInventory(item.productInventories[0])"
                                    v-b-modal.calculate
                                >
                                    <i class="fas fa-calculator"></i>
                                </p-button>
                            </template>
                        </p-number>
                    </template>
                    <template
                        v-slot:cell(eventPrice)="{
                            value,
                            item
                        }"
                    >
                        <p-number
                            label=""
                            name="Event Price"
                            :value="getEventPrice(item.productInventories[0])"
                            disabled="disabled"
                            input-type="money"
                            left-align
                            class="pt-3"
                        >
                            <template v-slot:append>
                                <p-button
                                    class="pl-0"
                                    @click="openSalesEvent(item)"
                                    v-if="$store.getters.featureFlagEnabled('Sales Events')"
                                >
                                View
                                </p-button>
                            </template>
                        </p-number>
                    </template>
                     
                    <template
                        v-slot:cell(lastSold)="{
                            value,
                            item
                        }"
                    >
                        <span> 
                            <span v-if="item.productInventories[0].lastSold">
                            {{
                                dateFormatter(
                                    item.productInventories[0].lastSold
                                )
                            }}
                            {{
                                datetimeToLocalTimeOnlyFormatter(
                                    item.productInventories[0].lastSold
                                )
                            }}
                            </span>
                            <span v-else>N/A</span>
                        </span>
                        
                    </template>
                    <template
                        v-slot:cell(stockOnHand)="{
                            value,
                            item
                        }"
                    >
                        <p-number
                            label=""
                            name="Stock On Hand"
                            v-model="item.productInventories[0].stockOnHand"
                            disabled="disabled"
                            input-type="integer"
                            left-align
                            class="pt-3"
                        >
                            <template v-slot:append>
                                <p-button @click="showSOHModal(item.productInventories[0], item.productInventoryLocations)">
                                    Edit
                                </p-button>
                            </template>
                        </p-number>
                    </template>
                    <template
                        v-slot:cell(onOrder)="{
                            value,
                            item
                        }"
                    >
                        <div>{{integerFormatter(item.productInventories[0].onOrder)}}</div>
                    </template>
                    <template
                        v-slot:cell(totalStockOnHand)="{
                            value,
                            item
                        }"
                    >
                        <span
                            class="clickable-values"
                            @click="showAllLocationsDetails(item)"
                            >{{   integerFormatter(item.productInventories[0].totalStockOnHand , "N/A")}}</span
                        >
                    </template>
                    <template
                        v-slot:cell(totalOnOrder)="{
                            value,
                            item
                        }"
                    >
                        <span
                            class="clickable-values"
                            @click="showAllLocationsDetails(item)"
                            >{{ integerFormatter(item.productInventories[0].totalOnOrder , "N/A") }}</span
                        >
                    </template>
                    <template
                        v-slot:cell(isActive)="{
                            value,
                            item
                        }"
                    >
                        <div class="extra-padding">
                            <p-checkbox
                                :use-switch="true"
                                v-model="item.isActive"
                                size="lg"
                            >
                            </p-checkbox>
                            <div class="mt-2">
                                {{ item.isActive ? 'Active' : 'Inactive' }}
                            </div>
                        </div>
                    </template>
                </p-table>
                <p-modal
                    size="lg"
                    name="addVariants"
                    label="Manage Variants"
                    no-close-on-backdrop
                >
                 <template v-slot:default>
                 <p-card>
                    <b-row>
                         <b-col cols="6" sm="6">
                            <p-advanced-select
                                label=""
                                data-type="productVariantGroups"
                                v-model="productFamily.gmVariantGroup1Id"
                                disabled
                            >
                            </p-advanced-select>
                        </b-col>
                        <b-col cols="6" sm="6">
                            <p-advanced-select
                                label=""
                                data-type="productVariantGroups"
                                v-model="productFamily.gmVariantGroup2Id"
                                disabled
                            >
                            </p-advanced-select>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="6" sm="6">
                            <p-table
                                ref="VariantTable1"
                                :items="variants1"
                                :fields="fieldsVariants"
                                :enableRowSelection="false"
                                :per-page="200"
                                class="wide"
                                outlined
                            >
                            <template v-slot:cell(isActive)="{ value, item }">
                                <div ><p-checkbox
                                    label=""
                                    class="ml-3"
                                    v-model="item.isCheckedVarient"                                 
                                    :disabled="isExistingVariant(item)"
                                     @change="calculateSKU()"
                                />
                                </div>
                            </template>
                            </p-table>
                        </b-col>
                        <b-col cols="6" sm="6">
                                <p-table
                                ref="VariantTable2"
                                :items="variants2"
                                :fields="fieldsVariants"
                                :enableRowSelection="false"
                                :per-page="200"
                                class="wide"
                                outlined
                            >
                             <template v-slot:cell(isActive)="{ value, item }">
                                <div ><p-checkbox
                                    label=""
                                    class="ml-3"
                                    v-model="item.isCheckedVarient"                               
                                    :disabled="isExistingVariant2(item)"
                                     @change="calculateSKU()"
                                />
                                </div>
                            </template>
                            </p-table>
                        </b-col>                         
                    </b-row>
                 </p-card>
                 </template>
             <template v-slot:modal-footer>
                <b-row>
                    <b-col>
                        <p-button
                            variant="outline-primary"
                            @click="$bvModal.hide('addVariants')"
                            class="mr-2"
                            >Cancel</p-button
                        >
                        <p-button
                            variant="primary"
                            @click="handleGenerateSKU"
                            :disabled="SKUCount<=0"
                            class="float-right"
                            >Generate ({{ SKUCount }})
                            SKU{{ SKUCount > 1 ? 's' : '' }}</p-button
                        >
                    </b-col>
                </b-row>
            </template>
                </p-modal>
            </p-card>
            <p-card>
                <b-row>
                    <b-col>
                        <h4>Online Profile</h4>
                    </b-col>
                    <b-col cols="auto" class="ml-auto mr-2">
                        <p-checkbox
                            label="Publish Active SKUs Online?"
                            :use-switch="true"
                            v-model="
                                productFamily.onlineProfileProperties
                                    .isPublishOnline
                            "
                            size="lg"
                        >
                        </p-checkbox>
                    </b-col>
                </b-row>
                <b-row class="mt-1">
                    <b-col cols="12" lg="4" xl="4">
                        <p-input
                            label="Website Display Name"
                            name="shortDescriptionEditor"
                            v-model="
                                productFamily.onlineProfileProperties
                                    .shortDescription
                            "
                            rules="max:200"
                        />
                    </b-col>
                    <b-col cols="12" lg="3" xl="3">
                        <p-number
                            label="Default Inventory Tolerance"
                            input-type="integer"
                            name="onlineInventoryTolerance"
                            v-model="
                                productFamily.onlineProfileProperties
                                    .inventoryTolerance
                            "
                        >
                        </p-number>
                    </b-col>

                    <b-col cols="12" lg="3" xl="3">
                        <p-number
                            input-type="money"
                            v-model="
                                productFamily.onlineProfileProperties
                                    .shippingFee
                            "
                            @focus="
                                initialShippingFee =
                                    productFamily.onlineProfileProperties
                                        .shippingFee
                            "
                            rules="min_value:0"
                            name="onlineShippingOverride"
                            label="Shipping Override"
                            left-align
                        />
                    </b-col>
                </b-row>
                <b-row class="mt-3 pb-3" cols="12" cols-sm="12" cols-xl="12">
                    <b-col cols="12" lg="12">
                        <label>Long Description</label>
                        <custom-quill-editor toolbar="minimal" v-model='productFamily.onlineProfileProperties.longDescription' />
                    </b-col>
                </b-row>
            </p-card>

            <p-card>
                <b-row>
                    <b-col cols="12" lg="12" xl="2">
                        <h4>Web Catalogs</h4>
                    </b-col>
                    <b-col cols="12" lg="12" xl="2">
                        <p-checkbox
                            v-model="filterSelected"
                            size="md"
                            label="Show Only Selected"
                        ></p-checkbox>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <p-table
                            :items="getItems"
                            v-model="productFamily.catalogProducts"
                            :fields="fieldsCatalog"
                            :disable-row-check="
                                data => {
                                    return (
                                        data.status.toLocaleLowerCase() ==
                                        'closed'
                                    );
                                }
                            "
                            :is-selected-function="isSelected"
                            :show-select-all="false"
                            :per-page="20"
                            class="wide"
                        >
                        </p-table>
                    </b-col>
                </b-row>
            </p-card>

            <p-modal
                name="addBrand"
                label="Add Brand"
                size="md"
                no-close-on-backdrop
                no-close-esc
                @shown="showModal"
                ref="addBrandModal"
            >
                <template v-slot:default>
                    <p-form ref="form">
                        <div>
                            <b-row>
                                <b-col>
                                    <p-input
                                        label="Brand Name"
                                        vid="brandName"
                                        v-model="brandName"
                                        rules="required|max:50"
                                        ref="setFocus"
                                    />
                                </b-col>
                            </b-row>
                        </div>
                    </p-form>
                </template>

                <template v-slot:modal-footer>
                    <b-row>
                        <b-col class=" mb-3 text-center">
                            <p-button
                                :is-busy="addingBrandIsBusy"
                                variant="outline-primary"
                                @click="handleAddBrand"
                                >OK</p-button
                            >
                        </b-col>
                    </b-row>
                </template>
            </p-modal>
            <PriceCalculator
                :initial-values="getInventory"
                :parameters="parameters"
            ></PriceCalculator>
            <p-modal
                name="addSize"
                label="Add New Size"
                size="md"
                no-close-on-backdrop
                no-close-esc
                ok-only
                @ok.prevent="handleAddSize"
                @shown="showModal"
                ref="addSizeModal"
            >
                <template v-slot:default>
                    <p-form ref="form">
                        <div>
                            <b-row>
                                <b-col>
                                    <p-input
                                        label="Size Name"
                                        vid="SizeDescription"
                                        v-model="sizeDescription"
                                        rules="required|max:80"
                                        ref="setFocus"
                                    />
                                </b-col>
                            </b-row>
                        </div>
                    </p-form>
                </template>
            </p-modal>
            <p-modal
                name="addColor"
                label="Add New Color"
                size="md"
                no-close-on-backdrop
                no-close-esc
                ok-only
                @ok.prevent="handleAddColor"
                @shown="showModal"
                ref="addColorModal"
            >
                <template v-slot:default>
                    <p-form ref="form">
                        <div>
                            <b-row>
                                <b-col>
                                    <p-input
                                        label="Color Name"
                                        vid="ColorDescription"
                                        v-model="colorDescription"
                                        rules="required|max:80"
                                        ref="setFocus"
                                    />
                                </b-col>
                            </b-row>
                        </div>
                    </p-form>
                </template>
            </p-modal>
            <p-modal
                name="allLocationsDetails"
                label="SKU Stock on Hand & On Order at All Locations"
                size="xl"
                no-close-on-backdrop
                no-close-esc
                ref="allLocationsDetailsModal"
            >
            <template v-slot:modal-title>
                 <h3>SKU Stock on Hand & On Order at All Locations  <small>({{productItem.defaultBarcode  !=null ? `${productItem.defaultBarcode}`: ''}}{{productItem.gmVariant1 != null ? `, ${productItem.gmVariant1}` :''}}{{productItem.gmVariant2 != null ? `, ${productItem.gmVariant2}` :''}}) </small></h3>
            </template>
                <template v-slot:default>
                    <p-form ref="form">
                        <div>
                            <b-row>
                                <b-col>
                                    <p-table
                                        ref="locationsTable"
                                        :items="allLocationOptions"
                                        :fields="fieldsLocation"
                                        :enableRowSelection="false"
                                        :per-page="500"
                                        class="wide table-striped "
                                    >
                                        <template v-slot:cell(name)="{ value,  item }" >
                                            <div>
                                                {{item.name}} {{addressFormatter(item.address, $store) != ""  ? `(`+addressFormatter(item.address, $store)+`)` : ''}}
                                            </div>
                                        </template>
                                  </p-table>
                                </b-col>
                            </b-row>
                        </div>
                    </p-form>
                </template> 
                <template v-slot:modal-footer="{  cancel }">
                    <b-row class="w-100">
                        <b-col class="mb-3 text-center">
                            <p-button
                                variant="outline-primary"
                                @click="cancel"
                                class="ml-2"
                            >
                                Close
                            </p-button>
                        </b-col>
                    </b-row>
                </template>
            </p-modal>
            <BarcodeListModal
                :currentVariant="currentVariant"
                @ok="saveBarcode"
            ></BarcodeListModal>
            <StockAdjustment
                :adjustmentData="stockAdjustment"
                @ok="stockAdjustmentSucceeded"
            />
            <SalesEventModal
                :productInventory="getInventory"
                :eventPrice="getEventPriceModal"
            ></SalesEventModal>
        </div>
    </p-form>
</template>

<script>
import axios from 'axios';
import StockAdjustment from '@/components/StockAdjustment.vue';
import BarcodeListModal from './../BarcodeListModal';
import PrintLabel from './../PrintLabelGM.vue';
import pricing from '@/services/PricingCalculations.js';
import PriceCalculator from '../PriceCalculator.vue';
import PCard from '../../../components/p-card.vue';
import 'quill/dist/quill.snow.css';
import SalesEventModal from './SalesEventModal.vue';
import StoreListManager from '@/components/mixins/StoreListManager.js';
import {integerFormatter } from "../../../components/Common/Formatters.js";
import selectListOptionsDataContext from '@/services/selectListOptions.dataContext.js';
import { addressFormatter,dateFormatter,datetimeToLocalTimeOnlyFormatter } from '@/components/Common/Formatters.js';

export default {
    mixins: [StoreListManager],
    props: {
        parameters: Object,
        product: Object,
        products: Array,
        isNew: Boolean,
        productFamily: Object,
        productInventory: Object
    },
    data() {
        return {
            selectListOptions: {
                webCatalogs: [],
                states: []
            },
            productItem:null,
            isLoading: false,
            filterSelected: false,
            currentVariant: { barcodes: [], defaultBarcode: null },
            salesEvent: {},
            productTypes: [
                { value: true, text: 'Matrix' },
                {value: false, text: 'Common' },

            ],
            brandName: '',
            defaultSupplier: '',
            expectedMargin: 0,
            stockAdjustment: {},
            sizeDescription: null,
            colorDescription: null,
            defaultEventPrice: null,
            formIsDirty: false,
            addingBrandIsBusy: false,
            productVariantDetails: {
                productVariantId: null,
                productVariantTypeId: null,
                variantName: '',
                status: '',
                productVariantGroupings: []
            },
            fieldsCatalog: [
                {
                    key: 'text',
                    label: 'Name',
                    sortable: true
                },
                {
                    key: 'description',
                    label: 'Description'
                },
                {
                    key: 'groups',
                    label: 'Catalog Group(s)'
                },
                {
                    key: 'status',
                    label: 'Status'
                }
            ],
            fieldsSKU: [
                {
                    key: 'productImageId',
                    label: ''
                },
                {
                    key: 'sku',
                    label: 'SKU'
                },
                {
                    key: 'gmVariant1',
                    label: 'Variant 1'
                },
                {
                    key: 'gmVariant2',
                    label: 'Variant 2'
                },
                {
                    key: 'gmVariant1Id',
                    label: '',
                    hidden:true
                },
                {
                    key: 'gmVariant2Id',
                    label: '',
                    hidden:true
                },
                {
                    key: 'defaultBarcode',
                    label: 'Default Barcode'
                },
                {
                    key: 'sellingPrice',
                    label: 'Selling Price'
                },
                {
                    key: 'eventPrice',
                    label: 'Event Price'
                },
                {
                    key:'lastSold',
                    label:'Last Sold',
                    thClass: 'last-sold',
                    tdClass: 'last-sold'
                },
                {
                    key: 'stockOnHand',
                    label: 'Stock on Hand',
                    thClass: 'text-center custom-border-left',
                    tdClass: 'text-center custom-border-left'
                },
                {
                    key: 'onOrder',
                    label: 'On Order',
                    thClass: 'text-center custom-border-right',
                    tdClass: 'text-center custom-border-right'
                },
                {
                    key: 'totalStockOnHand',
                    label: 'Stock on Hand',
                    thClass: 'text-center',
                    tdClass: 'text-center' 
                },
                {
                    key: 'totalOnOrder',
                    label: 'On Order',
                    thClass: 'text-center custom-border-right',
                    tdClass: 'text-center custom-border-right'
                },
                {
                    key: 'isActive',
                    label: 'Status'
                }
            ],
            fieldsLocation: [
                {
                    key: 'name',
                    label: 'Location',
                    tdClass:'location-width' 
                },
                {
                    key: 'stockOnHand',
                    label: 'Stock on Hand (SOH)',
                    thClass: 'text-center',
                    tdClass: 'text-center',
                    formatter: (value) => integerFormatter(value, "-")
                },
                {
                    key: 'onOrder',
                    label: 'On Order',
                    thClass: 'text-center',
                    tdClass: 'text-center',
                    formatter: (value) => integerFormatter(value, "-")
                }
            ],
            fieldsVariants: [                
                {
                    key: 'isActive',
                    label: ''
                },
                {
                    key: 'name',
                    label: 'Variant Name'
                },                
            ],
            SKUInventory:{
            },
            SKUEventPrice:null,
            currentIndex:0,
            allLocationOptions:[],
            variants1:[{
                         isCheckedVarient:false
                        }],
            variants2:[{
                        isCheckedVarient:false
                     }],
            existingVariants1: [],
            existingVariants2:[],
            SKUCount: 0,
            emptyProduct:{
                sku	:	null,
                priceTagTypeId	:	null,
                dccId	:	0,
                gmVariant1	:	null,
                gmVariant2	:	null,
                barcodes:[],
                defaultBarcode	:	null,
                isActive	:	true,
                productInventories:[{
                    productInventoryId	:	0,
                    sku	:	null,
                    locationId	:	0,
                    stockOnHand	:	null,
                    isEventPercentage	:	null,
                    lastSaleDate	:	null,
                    sellingPrice	:	0,
                    eventPrice	:	null,
                    lastsold: null,
                    eventStartDate	:	null,
                    eventEndDate	:	null,
                    onOrder	:	0,
                    cost	:	0,
                    accountingCost	:	null,
                    margin	:	0,
                    retail	:	0,
                    minStock	:	null,
                    maxStock	:	null,
                    newSellingPrice	:	null,
                    newSellingPriceStartDate	:	null,
                    totalStockOnHand	:	null,
                    totalOnOrder	:	0
                }],
                gmVariant1Id	:	null,
                gmVariant2Id	:	null,
                gmVariantGroup1Id	:	null,
                gmVariantGroup2Id	:	null,
                productImage	:	null

            }
        };
    },
    computed: {
        getInventory(){
            return this.SKUInventory;
        },
        getEventPriceModal(){
            return this.SKUEventPrice;
        },
        getItems() {
            return this.filterSelected
                ? this.selectListOptions.webCatalogs.filter(
                      x => this.isSelected(x)
                  )
                : this.selectListOptions.webCatalogs;
        },
        getDefaultSupplier() {
            let isEmpty =
                this.productFamily.suppliersProperties === null ||
                this.productFamily.suppliersProperties.filter(
                    x => x.isDefault === true
                ).length === 0;
            return isEmpty
                ? ''
                : this.productFamily.suppliersProperties.filter(
                      x => x.isDefault === true
                  )[0].vendorName;
        },
        newSellingPriceStartDateRules() {
            return this.product.newSellingPrice ? 'required' : '';
        },
        discountAmountValidationRules() {
            if (this.productInventory.isEventPercentage == null)
                return 'min_value:0.01|max_value:100';

            return this.productInventory.isEventPercentage
                ? 'required|min_value:0.01|max_value:100'
                : 'required|min_value:.01|max_value:' +
                      this.productInventory.sellingPrice;
        },

        eventStartDateRule() {
            return this.formIsDirty
                ? this.productInventory.eventPrice != null &&
                  this.productInventory.eventPrice != 0
                    ? 'required|dateLessThanOrEqual:@eventPriceEndDate'
                    : 'dateLessThanOrEqual:@eventPriceEndDate'
                : '';
        },
        eventEndDateRule() {
            return this.formIsDirty
                ? this.productInventory.eventPrice != null &&
                  this.productInventory.eventPrice != 0
                    ? 'required|dateGreaterOrEqual:@eventPriceStartDate'
                    : 'dateGreaterOrEqual:@eventPriceStartDate'
                : '';
        },
        getEventStartDate() {
            var date = new Date();
            date.setDate(date.getDate() + 1);

            if (!this.productInventory.eventStartDate) return date;

            var eventStartDate = new Date(this.productInventory.eventStartDate);

            if (eventStartDate < date) {
                date = eventStartDate;
            }

            return date;
        },
        getEventEndDate() {
            var date = new Date();
            date.setDate(date.getDate() + 1);

            if (!this.productInventory.eventEndDate) return date;

            var eventEndDate = new Date(this.productInventory.eventEndDate);

            if (eventEndDate < date) {
                date = eventEndDate;
            }

            return date;
        },
        primaryURL() {
            return this.productFamily.imageUrl
                ? this.productFamily.imageUrl
                : '/img/no-img.png';
        }
    },
    methods: {
       async calculateSKU() {
           if(this.variants2.length==0){
               this.SKUCount =
                this.variants1.filter(x => x.isCheckedVarient===true).length
           }else{
               this.SKUCount =
                ((this.variants1.filter(x => x.isCheckedVarient===true).length)
                          *
                (this.variants2.filter(x => x.isCheckedVarient===true)
                          .length+this.existingVariants2.length))
                          +
                (this.variants2.filter(x => x.isCheckedVarient===true)
                          .length * this.existingVariants1.length);
           }
            
        },
        handleGenerateSKU: async function() {
            this.emptyProduct.productInventories[0].locationId = await selectListOptionsDataContext.getSelectedLocationIdAsync();
             this.$bvModal
                .msgBoxConfirm(
                    `Product updates will create ${this.SKUCount} new SKUs.`
                )
                .then(value => {
                    if (value) {                        
                        this.emptyProduct.productInventories[0].sellingPrice = this.productInventory.sellingPrice;
                        this.emptyProduct.productInventories[0].cost = this.productInventory.cost;
                        this.emptyProduct.productInventories[0].margin = this.productInventory.margin;

                    let newVariants1 = JSON.parse(JSON.stringify(this.variants1.filter(x => x.isCheckedVarient===true)));
                    let oldVariants1 = JSON.parse(JSON.stringify(this.variants1.filter(x => this.existingVariants1.some(y => y == x.name))));
                    let newVariants2 = JSON.parse(JSON.stringify(this.variants2.filter(x => x.isCheckedVarient===true)));
                    let oldVariants2 = JSON.parse(JSON.stringify(this.variants2.filter(x => this.existingVariants2.some(y => y == x.name))));
                    let updatedProductFamily = JSON.parse(
                            JSON.stringify(this.productFamily));
                        if(this.variants2.length==0){
                            for(let i = 0; i < newVariants1.length; i++){
                                
                                updatedProductFamily.products.push(
                                            this.emptyProduct
                                        );
                                    updatedProductFamily.products[
                                            updatedProductFamily.products.length - 1
                                        ] = {
                                            ...updatedProductFamily.products[
                                                updatedProductFamily.products.length - 1
                                            ],
                                            gmVariant1Id: newVariants1[i].productVariantId,
                                            gmVariant2Id: null
                                        };
                            }
                        }else{
                            for(let i = 0; i < newVariants1.length; i++){
                                for (let j = 0; j < oldVariants2.length; j++) {
                                    updatedProductFamily.products.push(
                                            this.emptyProduct
                                        );
                                    updatedProductFamily.products[
                                            updatedProductFamily.products.length - 1
                                        ] = {
                                            ...updatedProductFamily.products[
                                                updatedProductFamily.products.length - 1
                                            ],
                                            gmVariant1Id: newVariants1[i].productVariantId,
                                            gmVariant2Id: oldVariants2[j].productVariantId
                                        };
                                }
                                for (let j = 0; j < newVariants2.length; j++) {
                                    updatedProductFamily.products.push(
                                            this.emptyProduct
                                        );
                                    updatedProductFamily.products[
                                            updatedProductFamily.products.length - 1
                                        ] = {
                                            ...updatedProductFamily.products[
                                                updatedProductFamily.products.length - 1
                                            ],
                                            gmVariant1Id: newVariants1[i].productVariantId,
                                            gmVariant2Id: newVariants2[j].productVariantId
                                        };
                                }
                            }
                            for(let i = 0; i < newVariants2.length; i++){
                                for (let j = 0; j < oldVariants1.length; j++) {                       
                                    updatedProductFamily.products.push(
                                            this.emptyProduct
                                        );
                                    updatedProductFamily.products[
                                            updatedProductFamily.products.length - 1
                                        ] = {
                                            ...updatedProductFamily.products[
                                                updatedProductFamily.products.length - 1
                                            ],
                                            gmVariant1Id: oldVariants1[j].productVariantId,
                                            gmVariant2Id: newVariants2[i].productVariantId
                                        };
                                }
                            }
                        }
                    
                    this.isBusy = true;
                    axios
                            .post('products/generalmerchandise', updatedProductFamily)
                            .then(() => {
                                this.$emit('refreshPage');                
                                this.loadData();
                                this.$bvModal.hide('addVariants');
                                this.$toasted.global
                                    .app_success(
                                        `Product ${updatedProductFamily.description} saved successfully.`
                                    )
                                    .goAway(5000);
                            })
                            .finally(() => {
                                this.isBusy = false;
                            });
                            
                    }
                });
        },
        isExistingVariant(item){
             return this.existingVariants1.some(x => x == item.name); 
        },
        isExistingVariant2(item){
             return this.existingVariants2.some(x => x == item.name); 
        },
        async showPrintLabel(){
            if(this.product.priceTagTypeId && this.product.defaultBarcode && this.product.productInventories[0].productInventoryId == 0 || this.product.productInventories[0].productInventoryId == null){
                this.$bvModal
                    .msgBoxOk(
                        'You must save the product before you can print this label.'
                    )
            }else if(this.product.priceTagTypeId && this.product.defaultBarcode && this.product.productInventories[0].productInventoryId > 0){
                this.$emit('savePage', true);
            }
            
                    
        },
        integerFormatter: integerFormatter,
        addressFormatter:addressFormatter,
         dateFormatter: dateFormatter,
         datetimeToLocalTimeOnlyFormatter: datetimeToLocalTimeOnlyFormatter,
        async showAllLocationsDetails(item){
              
           let locationId = await selectListOptionsDataContext.getSelectedLocationIdAsync();
            this.productItem = item;
            axios
                .get('products/getonhandonorder', {
                    params: { sku: item.sku, locationId: locationId }
                })
                .then(resp => {
                    this.allLocationOptions = resp.data;
                    this.$refs.allLocationsDetailsModal.show();
                });
        },
        getEventPrice(productInventory) {
            return pricing.computeEventPrice(productInventory.retail, productInventory.salesEvent.priceMod);  
        },
        updateProductInventory(productInventory){
            this.SKUInventory=JSON.parse(
                JSON.stringify(productInventory));
            setTimeout(() => {
                this.$bvModal.show('calculate')
            },50);
        },
        async showSOHModal(variant, productInventoryLocations) {
            if (variant.sellingPrice === 0) {
                await this.$bvModal
                    .msgBoxConfirm(
                        'Are you sure you want make a $0 stock adjustment?'
                    )
                    .then(value => {
                        if (value) {
                            this.openSOHModal(variant, productInventoryLocations);
                        }
                    });
            } else {
                this.openSOHModal(variant, productInventoryLocations);
            }
        },
        openSOHModal(variant, productInventoryLocations) {
            this.$set(
                this.stockAdjustment,
                'sku',
                variant.sku == 0 ? null : variant.sku
            );

            this.stockAdjustment.cost = variant.cost;
            this.stockAdjustment.retail = variant.sellingPrice;

            selectListOptionsDataContext.getSelectedLocationIdAsync()
                .then(locationId => {
                    // The productInventory record does not yet exist for this location
                    if(!productInventoryLocations.some(x => x.sku == variant.sku && x.locationId == locationId)) {
                        this.$bvModal
                            .msgBoxConfirm(
                                `Are you sure you wish to create a new product inventory record for SKU ${variant.sku} for the currently selected location?`
                            )
                            .then(value => {
                                if (value) {
                                    this.$bvModal.show('StockAdjustmentModal');
                                }
                            });
                    }
                    else{
                        this.$bvModal.show('StockAdjustmentModal');
                    }
                });
        },
        stockAdjustmentSucceeded(data) {
            this.$emit('savePage', null);
            let product = this.products.find(x=>x.sku==this.stockAdjustment.sku);
            product.productInventories[0].stockOnHand += data.qty;
            product.productInventories[0].totalStockOnHand += data.qty;
            this.$emit('clearDirtyFlag');
        },
        openSalesEvent(product) {
            this.salesEvent = JSON.parse(
                JSON.stringify(product.productInventories[0]));
            this.SKUInventory=JSON.parse(
                JSON.stringify(product.productInventories[0]));
            this.SKUEventPrice=this.getEventPrice(this.SKUInventory);
            setTimeout(() => {
                this.$bvModal.show('SalesEventModal')
            },50);
        },
        openBarcodes: async function(product) {
            this.currentVariant =  JSON.parse(
                JSON.stringify(product));
            this.$bvModal.show('BarcodeListModal');
        },
        saveBarcode(data) {
            let product = this.products.find(x=>x.sku==this.currentVariant.sku);
            product.barcodes = data.barcodes.map(b => ({ ...b }));
            product.defaultBarcode = data.defaultBarcode;
        },
        loadData() {
            this.defaultSupplier = this.getDefaultSupplier;
            this.calculateMargin();
            this.dccChanged();
            this.defaultEventPrice = this.productInventory.eventPrice;
        },
        newPrimaryURL(value) {
            this.productFamily.imageUrl = value;
        },
        ImageURL(index, value) {
            this.productFamily.onlineProfileProperties.images[
                index
            ].dataURL = value;
        },
        SKUImageURL(item, value){
            let product = this.products.find(x=>x.sku==item.sku);
            product.productImage.dataURL = value;
        },
        handleAddBrand(event) {
            if (!this.brandName) {
                event.preventDefault();
                return;
            }

            this.addingBrandIsBusy = true;
            axios
                .post('/brand', {
                    brandName: this.brandName
                })
                .then(resp => {
                    this.$refs.addBrandModal.hide();
                    this.productFamily.productBrandId = resp.data;
                    this.brandName = '';
                })
                .finally(() => {
                    this.addingBrandIsBusy = false;
                });
        },
        calculateMargin() {
            this.productInventory.margin = pricing.computeMargin(
                this.productInventory.cost,
                this.productInventory.sellingPrice
            );
        },
        dccChanged: async function() {
            if (!this.product?.dccId) {
                return;
            }
            this.products.forEach(product => {
                product.dccId = this.product.dccId; // Set the chosen DCC on the ProductFamily on all the Products
            });
            axios
                .get('products/dccdefaults', {
                    params: { dccId: this.product.dccId }
                })
                .then(resp => {
                    if (resp.data.margin != null) {
                        this.expectedMargin = resp.data.margin;
                        this.product.priceTagTypeId =
                            resp.data.priceTagTypeId ??
                            this.product.priceTagTypeId;
                    }
                });
        },
        refreshDefaultSupplier() {
            this.defaultSupplier = this.getDefaultSupplier;
        },
        handleAddSize: async function() {
            if (!(await this.$refs.form.validate())) {
                return;
            }

            this.productVariantDetails.productVariantTypeId = 1;
            this.productVariantDetails.variantName = this.sizeDescription;
            this.productVariantDetails.status = 'Active';

            axios
                .post('productvariant', this.productVariantDetails)
                .then(response => {
                    this.product.gmVariant1Id = response.data;
                });

            this.$refs.addSizeModal.hide();
        },
        handleAddColor: async function() {
            if (!(await this.$refs.form.validate())) {
                return;
            }

            this.productVariantDetails.productVariantTypeId = 2;
            this.productVariantDetails.variantName = this.colorDescription;
            this.productVariantDetails.status = 'Active';

            axios
                .post('productvariant', this.productVariantDetails)
                .then(response => {
                    this.product.gmVariant2Id = response.data;
                });

            this.$refs.addColorModal.hide();
        },
        showModal() {
            this.brandName = '';
            this.sizeDescription = '';
            this.colorDescription = '';
            this.$refs.setFocus.focus();
        },
        SetDefaultValuesForOnlineProfile() {
            if (!this.productFamily.onlineProfileProperties.webPrice) {
                this.productFamily.onlineProfileProperties.webPrice = this.product.productInventories[0].sellingPrice;
            }
            if (!this.productFamily.onlineProfileProperties.shortDescription) {
                this.productFamily.onlineProfileProperties.shortDescription = this.productFamily.description;
            }
        },
        dccFilter(dcc) {
            return dcc.filter == 1;
        },
        SetEventDates() {
            if (
                this.product.eventPrice != null &&
                this.product.eventPrice != '0'
            ) {
                let today = new Date();
                today.setHours(0, 0, 0, 0);

                if (this.product.eventStartDate === null) {
                    this.product.eventStartDate = today;
                }

                if (this.product.eventEndDate == null) {
                    let tomorrow = new Date();
                    tomorrow.setDate(today.getDate() + 1);
                    tomorrow.setHours(0, 0, 0, 0);
                    this.product.eventEndDate = tomorrow;
                }

                this.formIsDirty = true;
            } else {
                this.product.eventStartDate = null;
                this.product.eventEndDate = null;
            }
        },
        clearDataEntry() {
            this.$bvModal
                .msgBoxConfirm('Are you sure you want clear the sales event?')
                .then(value => {
                    if (value) {
                        this.productInventory.isEventPercentage = null;
                        this.productInventory.eventPrice = null;
                        this.productInventory.eventStartDate = null;
                        this.productInventory.eventEndDate = null;
                        this.defaultEventPrice = null;
                    }
                });
        },
        getInputType(value) {
            return value ? 'percent' : 'money';
        },
        eventPercentageValidate() {
            this.formIsDirty = true;
            if (this.$refs.form != null) {
                this.$refs.form.validate();
            }
        },
        dateChanged() {
            this.formIsDirty = true;
        },
        isSelected(item) {
            return this.productFamily.catalogProducts.some(x => x.catalogId == item.catalogId);
        },
        showAddVariantsModal(){
            this.loadVariants1();
            this.loadVariants2();
            this.calculateSKU();
            this.SKUCount=0;            
            this.$bvModal.show('addVariants');
        },
        async loadVariants1(){              
            axios
                .get('ProductVariantGroup', {
                    params: { id: this.productFamily.gmVariantGroup1Id }
                })
                .then(resp => {
                    this.variants1 = resp.data.productVariantGroupings.filter(x => x.isActive === true);
                    this.existingVariants1 = [...new Set(this.products.map(item => item.gmVariant1))];
                });
        },
        async loadVariants2(){
            if(this.productFamily.gmVariantGroup2Id == null)
            {
                this.variants2=[];
                this.existingVariants2=[];
                return;
            }              
            axios
                .get('ProductVariantGroup', {
                    params: { id: this.productFamily.gmVariantGroup2Id }
                })
                .then(resp => {
                    this.variants2 = resp.data.productVariantGroupings.filter(x => x.isActive === true);
                    this.existingVariants2 = [...new Set(this.products.map(item => item.gmVariant2))];
                });
        },
        serializationToggleChange(){
            if(!this.productFamily.enableSerializationToggle){
                this.$toasted.global
                    .app_error(
                        `Serial Numbers exist for this item, cannot disable`
                    )
                    .goAway(5000);
                    setTimeout(() => {
                     this.productFamily.isSerializationActive = true;
                    }, 200);
                    return;
            }

            this.$nextTick(() => {                
                if(!this.productFamily.isSerializationActive) {   
                    this.productFamily.isDepEligible =  false; 
                }
            });
        },
        depEligibleToggleChange(){
            this.$nextTick(() => {                
                if(!this.productFamily.isSerializationActive) {   
                    this.$toasted.global
                    .app_error(
                        `The Serialized Product toggle must be Enabled to allow DEP Eligibility.`
                    )
                    setTimeout(() => {
                        this.productFamily.isDepEligible = false;
                    }, 200);
                    return;
                }
            });
        },        
        manageButtonClick(){
            this.$emit('manageButtonClick');
        }
    },
    mounted() {
        this.loadData();
    },
    watch: {
        $route: function(to, from) {
            if (to !== from) {
                this.loadData();
            }
        },
        'product.newSellingPrice': function(newValue, oldValue) {
            if (!oldValue) {
                var date = new Date();
                date.setDate(date.getDate() + 1);
                this.product.newSellingPriceStartDate = date;
            }
            if (!newValue) {
                this.product.newSellingPriceStartDate = null;
            }
        }
    },
    components: {
        StockAdjustment,
        BarcodeListModal,
        PrintLabel,
        PriceCalculator,
        PCard,
        SalesEventModal
    }
};
</script>
<style scoped lang="scss">
@import '@/styles/app/common/variables.scss';
/deep/ .location-width{
    max-width: 300px;
}
.resizedImg /deep/ .vue-dropzone {
    width: 50px !important;
    height: 50px !important;

    .dz-default.dz-message {
        display: none;
    }
}
.extra-padding {
    padding: 0 15px;
}
.clickable-values{
    cursor: pointer;
    color: $blue-darker;
    text-decoration: underline;
}
/deep/ .table td{
    vertical-align: middle;
}

/deep/ .custom-border-left{
    border-left:1px solid rgba(0, 0, 0, 0.12);
}

/deep/ .custom-border-right{
    border-right:1px solid rgba(0, 0, 0, 0.12);
}

/deep/ .btn-add-variant{
    display: inline-block;
}

/deep/ .card-title {
    display: inline-block;
}
/deep/.ql-image, /deep/.ql-video{
        display:none !important;
}
/deep/ .last-sold {
    max-width: 90px;
}

</style>
