<template>
    <div>
        <p-form @submit="onSubmit">
            <div
                class="container-fluid content-wrapper"
                v-if="physicalInventory"
            >
                <b-row class="content-heading">
                    <b-col>
                        <div>
                            {{ isNew ? 'Add' : 'Edit' }} Physical Inventory
                        </div>
                    </b-col>
                    <b-col class="text-right">
                        <p-button
                            v-if="!isNew"
                            class="mr-2"
                            variant="danger"
                            :is-busy="isBusy"
                            @click="deletePhysicalInventory"
                        >
                            Delete Fixture
                        </p-button>
                        <p-button type="submit" variant="primary" :is-busy="isBusy">
                            Save
                        </p-button>
                    </b-col>
                </b-row>
                <p-card>
                    <b-row cols="2" cols-lg="4">
                        <b-col>
                            <p-input
                                label="Fixture"
                                v-model="physicalInventory.fixture"
                                rules="required|max:50"
                                :disabled="!isNew"
                            />
                        </b-col>
                        <b-col>
                            <p-input
                                label="Area"
                                v-model="physicalInventory.area"
                                rules="required|max:50"
                                :disabled="!isNew"
                            />
                        </b-col>
                        <b-col>
                            <p-input
                                label="Batch"
                                v-model.number="physicalInventory.batch"
                                rules="required|numeric"
                                :disabled="!isNew"
                            />
                        </b-col>
                        <b-col>
                            <p-select
                                data-type="locations"
                                v-model="physicalInventory.locationId"
                                label="Location"
                                rules="required"
                                :disabled=true
                            />
                        </b-col>
                    </b-row>
                </p-card>
                <p-card v-if="!isNew">
                    <b-row>
                        <b-col cols="auto" class="pr-0 pb-1">
                            <p-button
                                variant="btn btn-outline-primary"
                                :is-busy="isBusy"
                                @click="printFixture"
                                >Print Fixture</p-button
                            >
                        </b-col>
                        <b-col cols="auto" class="ml-auto pr-0 pb-1">
                            <p-button
                                @click="onRemoveClick"
                                class="mr-2"
                                variant="outline-danger"
                                :is-busy="isBusy"
                                >Delete Product(s)</p-button
                            >
                        </b-col>
                        <b-col cols="auto" class="pl-1 pb-1">
                            <ProductMultiSelectQuickSearch
                                label="Search Products"
                                :per-page="100"
                                name="itemSearch"
                                :is-busy="isBusy"
                                @selections="addSearchItems"
                            />
                        </b-col>

                        <!-- this is the modal for when their scan finds zero/multiple results. button is disabled by setting buttonLabel to "" -->
                        <ProductMultiSelectQuickSearch
                            name="multiResultSelect"                            
                            :per-page="100"
                            @selections="handleMultiResultSelect"
                            :prefill-search="searchPrefill"
                            single-select
                            :modal-label="multiResultLabel"
                            label=""
                        />
                    </b-row>
                    <b-row class="mt-2">
                        <b-col>
                            <p-table
                                foot-clone
                                no-footer-sorting
                                selectable
                                foot-variant="light"
                                :items="
                                    physicalInventory.physicalInventoryDetails
                                "
                                :fields="fields"
                                :per-page="itemsPerPage"
                                :current-page="currentPage"
                                empty-text=""
                                show-per-page-selector
                                v-on:pageChanged="onPageChanged"
                                @input="selectedRowsChanged"
                                ref="detailTable"
                            >
                                <template
                                    v-slot:cell(scanCode)="{
                                        value,
                                        item,
                                        index
                                    }"
                                >
                                    <p-input
                                        v-model="item.scanCode"
                                        name="ScanCode"
                                        :ref="'scanInputs_' + index"
                                        @keydown="
                                            scanCodeInputDebounce(
                                                item,
                                                false,
                                                index,
                                                $event
                                            )
                                        "
                                        rules="max:128"
                                        compact-format
                                    >
                                    </p-input>
                                </template>
                                <template
                                    v-slot:cell(qty)="{ value, item, index }"
                                >
                                    <p-number
                                        v-model.number="item.qty"
                                        name="Qty"
                                        input-type="integer"
                                        :ref="'qtyInputs_' + index"
                                        @keydown="
                                            handleQtyKeydown
                                            (
                                                $event,
                                                item,
                                                index
                                            )
                                        "
                                        @blur="handleQtyBlur(item)"
                                        rules="min_value:0"
                                        compact-format
                                    >
                                    </p-number>
                                </template>
                                <template v-slot:cell(gmVariant1)="{ value, item, index }">
                                    {{ item.bookVariant?bookVariantFormatter(item.bookVariant):item.gmVariant1 }}
                                </template>
                                <template v-slot:cell(gmVariant2)="{ value, item, index }">
                                    {{ item.gmVariant2 }}
                                </template>

                                <!-- footer -->
                                <template v-slot:foot(selected)="data">
                                </template>
                                <template v-slot:foot(scanCode)="data">
                                    <p-input
                                        v-model="newItem.scanCode"
                                        name="ScanCode"
                                        ref="newScanInput"
                                        placeholder="Scan new item..."
                                        @input="
                                            scanCodeInputDebounce(
                                                newItem,
                                                true,
                                                -1,
                                                $event
                                            )
                                        "
                                        @keydown="
                                            handleScanCodeKeydown($event)
                                        "
                                        rules="max:128"
                                        compact-format
                                    >
                                    </p-input>
                                </template>
                                <template v-slot:foot(qty)="data">
                                    <p-number
                                        v-model.number="newItem.qty"
                                        input-type="integer"
                                        name="Qty"
                                        ref="newQtyInput"
                                        @keydown="
                                            handleNewQtyKeydown($event, newItem)
                                        "                                        
                                        rules="min_value:0"
                                        compact-format
                                    >
                                    </p-number>
                                </template>
                                <template v-slot:foot(sku)="data">
                                    {{ newItem.sku }}
                                </template>
                                <template
                                    v-slot:foot(authorTitleOrDescription)="data"
                                >
                                    {{ newItem.authorTitleOrDescription }}
                                </template>
                                <template v-slot:foot(gmVariant1)="data">
                                    {{ newItem.bookVariant?bookVariantFormatter(newItem.bookVariant):newItem.gmVariant1 }}
                                </template>
                                <template v-slot:foot(gmVariant2)="data">
                                    {{ newItem.gmVariant2 }}
                                </template>
                                <template v-slot:foot(stockOnHand)="data">
                                    {{ newItem.stockOnHand }}
                                </template>
                            </p-table>
                        </b-col>
                    </b-row>
                </p-card>
                <b-row>
                    <b-col class=" mb-3 text-center">
                        <p-button variant="primary" type="submit" :is-busy="isBusy"
                            >Save</p-button
                        >
                    </b-col>
                </b-row>
            </div>
        </p-form>
    </div>
</template>

<script>
import axios from 'axios';
import NavigationGuard from '@/components/mixins/NavigationGuard.js';
import ProductMultiSelectQuickSearch from '@/components/ProductMultiSelectQuickSearch.vue';
import debounce from 'lodash/debounce';
import { integerFormatter, bookVariantFormatter } from '../../components/Common/Formatters.js';
import selectListOptionsDataContext from '@/services/selectListOptions.dataContext.js';

export default {
    components: {
        ProductMultiSelectQuickSearch
    },
    mixins: [NavigationGuard],
    props: {
        id: String
    },
    data() {
        return {
            isBusy:false,
            physicalInventory: null,
            currentItem: null,
            quickSearchSelections: null,
            itemsPerPage: 100,
            scanCodeDebounce: null,
            fields: [
                {
                    key: 'scanCode',
                    label: 'Scan Code',
                    automaticSearch: true
                },
                {
                    key: 'qty',
                    label: 'Quantity',
                    thClass: 'text-center'
                },
                {
                    key: 'sku',
                    label: 'SKU/XREF'
                },
                {
                    key: 'sku',
                    label: 'SKU',
                    automaticSearch: true
                },
                {
                    key: 'author',
                    label: 'Author',
                    automaticSearch: true,
                    hidden:true
                },
                {
                    key: 'title',
                    label: 'Title',
                    automaticSearch: true,
                    hidden:true
                },
                {
                    key: 'authorTitleOrDescription',
                    label: 'Description',
                    automaticSearch: true,
                    hidden:true
                },
                {
                    label: 'Author/Title or Description',
                    key: 'authorTitleOrDescription',
                    sortable: true
                },
                {
                    label: 'Variant 1',
                    key: 'gmVariant1',
                    sortable: true
                },
                {
                    label: 'Variant 2',
                    key: 'gmVariant2',
                    sortable: true
                },
                {
                    key: 'stockOnHand',
                    label: 'Stock On Hand',
                    thClass: 'text-center',
                    tdClass: 'text-right',
                    formatter: (value) => integerFormatter(value, "-")
                }
            ],
            searchPrefill: null,
            currentPage: 1,
            newItem: {
                scanCode: null,
                sku: null,
                qty: null,
                authorTitleOrDescription: null,
                gmVariant1:null,
                gmVariant2:null,
                bookVariant:null,
                stockOnHand: null
            },
            multiResultLabel: 'Multiple Products Found',
            selectedRows: [],
            reportParameters: {
                id:0
            },
        };
    },
    computed: {
        isNew() {
            return this.id === 'new';
        },
        headerComplete() {
            return (
                this.physicalInventory.fixture != null &&
                this.physicalInventory.area != null &&
                this.physicalInventory.batch != null &&
                this.physicalInventory.locationId != null
            );
        },
        allItemsValid() {
            return (
                this.physicalInventory.physicalInventoryDetails.filter(
                    d => d.sku == null
                ) == 0
            );
        }
    },
    mounted: function() {
        this.scanCodeDebounce = debounce(this.searchProducts, 525);
        this.loadData();
    },
    watch: {
        physicalInventory: NavigationGuard.$watcher,
        newItem:NavigationGuard.$watcher,
        $route: function(to, from) {
            if (to !== from) {
                this.loadData();
            }
        }
    },
    methods: {
        bookVariantFormatter:bookVariantFormatter,    
        scanCodeInputDebounce(newItem, fromNewInput, index, e) {
            if (this.enterOrTab(e.code)) {
                e.preventDefault();
            }
            this.scanCodeDebounce(newItem, fromNewInput, index);
        },
        async loadData() {
            var locId = await selectListOptionsDataContext.getSelectedLocationIdAsync();

            let promise = this.isNew
                ? axios.get('physicalinventory/new')
                : axios.get('physicalinventory', { params: { id: this.id } });

            promise.then(res => {
                this.physicalInventory = res.data;
                this.physicalInventory.locationId = locId;
                if (!this.isNew) {
                         this.dataLoaded();
                }
                
                this.$nextTick(() => {
                    this.$refs['newScanInput']?.focus();
                });
            });
        },
        searchProducts(item, fromNewInput, index) {
            if (item.scanCode) {
                axios
                    .post('productquicksearch/search', {
                        automatic: item.scanCode,
                        locationId: this.physicalInventory.locationId
                    })
                    .then(resp => {
                        if (resp.data.count == 1) {
                            var p = resp.data.data[0];
                            item.sku = p.sku;
                            item.authorTitleOrDescription =
                                p.authorTitleOrDescription;
                            item.gmVariant1 = p.gmVariant1;
                            item.gmVariant2 = p.gmVariant2;
                            item.bookVariant = p.bookVariant;
                            item.stockOnHand = p.stockOnHand;
                            item._rowVariant = null;
                            if (index > -1) {
                                this.$refs['qtyInputs_' + index].focus();
                            } else {
                                this.$refs['newQtyInput'].focus();
                            }
                        } else if (resp.data.count == 0 && fromNewInput) {
                            this.clearItem(item);
                        } else {
                            this.clearItem(item);

                            this.searchPrefill = item.scanCode;
                            this.currentItem = item;
                            this.multiResultLabel =
                                resp.data.count == 0
                                    ? 'Scan Not Found'
                                    : 'Multiple Products Found';
                            this.$nextTick(() => {
                                this.$bvModal.show('multiResultSelect');
                            });
                        }
                    });
            } else {
                //clear item
                item.qty = null;
                this.clearItem(item);
            }
        },
        clearItem(item) {
            item.sku = null;
            item.authorTitleOrDescription = 'PRODUCT NOT FOUND';
            item.stockOnHand = 'N/A';
            item._rowVariant = 'danger';
        },
        handleScanCodeKeydown(e){            
            if (this.enterOrTab(e.code)) {
                e.preventDefault();
            }

        },
        handleNewQtyKeydown(e, item) {
            if (this.enterOrTab(e.code)) {
                e.preventDefault();

                if (e.shiftKey == true) {
                    this.$refs['newScanInput'].focus();
                    return;
                }

                this.addItem(item);
            }
        },        
        addItem(item) {
            if (item.sku) {
                this.physicalInventory.physicalInventoryDetails.push({
                    scanCode: item.scanCode,
                    sku: item.sku,
                    qty: item.qty,
                    authorTitleOrDescription: item.authorTitleOrDescription,
                    gmVariant1: item.gmVariant1,
                    gmVariant2: item.gmVariant2,
                    bookVariant: item.bookVariant,
                    stockOnHand: item.stockOnHand
                });
                let postItems=[];
                postItems[0]=item;
                this.postRange(postItems);
                this.clearNewItem();
                this.$refs['newScanInput'].focus();
            }
        },
        clearNewItem() {
            this.newItem.scanCode = '';
            this.newItem.qty = null;
            this.newItem.sku = null;
            this.newItem.authorTitleOrDescription = null;
            this.newItem.gmVariant1 = null;
            this.newItem.gmVariant2 = null;
            this.newItem.bookVariant = null;
            this.newItem.stockOnHand = null;
            this.newItem._rowVariant = null;
        },
        enterOrTab(code) {
            return code == 'Enter' || code == 'Tab' || code == 'NumpadEnter';
        },
        handleQtyKeydown(e, item, index) {
            if (this.enterOrTab(e.code)) {
                e.preventDefault();

                var nextFld = e.shiftKey
                    ? this.$refs['qtyInputs_' + (index - 1)]
                    : this.$refs['qtyInputs_' + (index + 1)];
                if (nextFld) {
                    nextFld.focus();
                } else {
                    this.$refs['newScanInput'].focus();
                }
            }
        },
        handleQtyBlur(item){
            let postItems=[];
            postItems[0]=item;
            this.postRange(postItems);
        },
        handleScanKeydown(e, item, index) {
            if (this.enterOrTab(e.code)) {
                e.preventDefault();

                var nextFld = e.shiftKey
                    ? this.$refs['qtyInputs_' + (index - 1)]
                    : this.$refs['qtyInputs_' + index];
                if (nextFld) {
                    nextFld.focus();
                }
            }
        },
        handleNewScanKeydown(e) {
            if (this.enterOrTab(e.code)) {
                e.preventDefault();

                var lastfield = this.getLastQtyFieldName();

                var nextFld = e.shiftKey
                    ? this.$refs[lastfield]
                    : this.$refs['newQtyInput'];
                if (nextFld) {
                    nextFld.focus();
                }
            }
        },
        getLastQtyFieldName() {
            var flds = Object.keys(this.$refs).filter(
                k => k.includes('qtyInputs_') && this.$refs[k] !== undefined
            );
            return flds[flds.length - 1];
        },
        deletePhysicalInventory() {
            this.$bvModal
                .msgBoxConfirm(
                    'This action will discard all Fixture details, Scanned Codes, and Quantities from your inventory counts. Are you sure you want to delete this information from your inventory record?',
                    {
                        okVariant: 'danger',
                        okTitle: 'Delete',
                        cancelTitle: 'Go Back'
                    }
                )
                .then(value => {
                    if (value) {
                        this.isBusy=true;
                        axios
                            .post(
                                'physicalInventory/delete',
                                this.physicalInventory
                            )
                            .then(resp => {
                                if (resp) {
                                    this.dataSaved();
                                    this.$router.push('/physicalinventory');
                                }
                            })
                            .finally(() => {
                                this.isBusy = false;
                            });
                    }
                });
        },
        postRange(items){
            let postData = {
                physicalInventoryDetails:[]
            }
            postData.physicalInventoryId = this.physicalInventory.physicalInventoryId;
            items.forEach(item => {
                if (!item.qty) {
                    item.qty = 0;
                }
                postData.physicalInventoryDetails.push({
                    physicalInventoryDetailId:item.physicalInventoryDetailId,
                    scanCode: item.sku.toString(),
                    sku: item.sku,
                    qty: item.qty,
                    authorTitleOrDescription: item.authorTitleOrDescription,
                    stockOnHand: item.stockOnHand
                });
            });
            
            axios
                .post('physicalInventory/postproduct', postData)
                .then(() => {
                    this.dataSaved();  
                    this.$toasted.global
                        .app_success(`Inventory records updated successfully.`)
                        .goAway(5000);
                });

        },
        printFixture(){
            this.isBusy = true;
            
            this.reportParameters.id = this.physicalInventory.physicalInventoryId;
            axios.post('physicalInventory/printfixtureHTML',this.reportParameters, { responseType: 'text/html' } )
                        .then((result) => {
                                var htmlContent = result.data;
                                var newTab = window.open();
                                newTab.document.write(htmlContent);
                                newTab.document.close();
                                this.$toasted.global.app_success(`Fixture generated successfully.`).goAway(5000);
                        }).finally(() => {
                            this.isBusy = false;
                        });
        },
         onSubmit() {
            //stop if any items are not valid
            if (!this.allItemsValid) {
                this.$toasted.global
                    .app_error('Please remove invalid rows before saving.')
                    .goAway(5000);
                return;
            }

            //Physical Inventory - the last input form row should be saved.
            if (this.newItem.sku) {
                this.physicalInventory.physicalInventoryDetails.push({
                    scanCode: this.newItem.scanCode,
                    sku: this.newItem.sku,
                    qty: this.newItem.qty,
                    authorTitleOrDescription: this.newItem
                        .authorTitleOrDescription,
                    gmVariant1:this.newItem.gmVariant1,
                    gmVariant2:this.newItem.gmVariant2,
                    bookVariant:this.newItem.bookVariant,
                    stockOnHand: this.newItem.stockOnHand
                });

                this.clearNewItem();
                this.$refs['newScanInput'].focus();
            }

            //update all blank quantities to zero
            this.physicalInventory.physicalInventoryDetails.forEach(i => {
                if (!i.qty) {
                    i.qty = 0;
                }
            });
            this.isBusy=true;
            axios
                .post('physicalInventory', this.physicalInventory)
                .then(response => {
                    this.dataSaved();
                    if (this.isNew) {
                        this.$router.push(
                            '/physicalInventory/' + response.data
                        );
                    }

                    this.$toasted.global
                        .app_success(`Inventory record saved successfully.`)
                        .goAway(5000);
                })
                .finally(() => {
                    this.isBusy = false;
                });
        },
        onRemoveClick() {
            this.$bvModal
                .msgBoxConfirm(
                    'This action will discard all selected Scan Codes and Quantities from your inventory counts. Are you sure you want to delete this information from your inventory record?',
                    {
                        okVariant: 'danger',
                        okTitle: 'Delete',
                        cancelTitle: 'Go Back'
                    }
                )
                .then(value => {
                    if (value) {
                        if (
                            this.selectedRows.length ==
                                this.physicalInventory.physicalInventoryDetails
                                    .length &&
                            this.selectedRows.length > 1
                        ) {
                            this.extraDeleteConfirm();
                        } else {
                            this.removeSelectedItems();
                        }
                    }
                });
        },
        extraDeleteConfirm() {
            this.$bvModal
                .msgBoxConfirm(
                    'You are about to remove ALL of your scanned products. Do you wish to proceed?',
                    {
                        okVariant: 'danger',
                        okTitle: 'Delete',
                        cancelTitle: 'Go Back'
                    }
                )
                .then(value => {
                    if (value) {
                        this.removeSelectedItems();
                    }
                });
        },
        selectedRowsChanged(items) {
            this.selectedRows = items;
        },
        removeSelectedItems() {
            this.physicalInventory.physicalInventoryDetails = this.physicalInventory.physicalInventoryDetails.filter(
                x => !this.selectedRows.find(y => y === x)
            );
        },
        addSearchItems(items) {
            items.forEach(item => {
                this.physicalInventory.physicalInventoryDetails.push({
                    scanCode: item.sku.toString(),
                    sku: item.sku,
                    qty: 0,
                    authorTitleOrDescription: item.authorTitleOrDescription,
                    stockOnHand: item.stockOnHand
                });
            });
            this.postRange(items);
        },
        handleMultiResultSelect(item) {
            if (item) {
                this.currentItem.sku = item.sku;
                this.currentItem.scanCode = item.sku.toString();
                this.currentItem.authorTitleOrDescription =
                    item.authorTitleOrDescription;
                this.currentItem.stockOnHand = item.stockOnHand;
                this.currentItem._rowVariant = null;
            }
        },
        onPageChanged(pageNum) {
            this.currentPage = pageNum;
        }
    }
};
</script>

<style></style>
