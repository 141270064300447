<template>
    <p-secure require-admin>
    <p-form @submit='onSubmit'>
        <div class='container-fluid content-wrapper' v-if='user'>
            <b-row class='content-heading'>
                <b-col>
                    <div>{{ isNew ? 'Add' : 'Edit' }} User</div>
                </b-col>
                <b-col class='text-right'>
                    <b-button
                        v-if='!isNew'
                        class='mr-2'
                        hidden
                        variant='danger'
                        @click='deleteUser'
                    >
                        Delete
                    </b-button>
                    <b-button type='submit' variant='primary'
                        >Save</b-button
                    >
                </b-col>
            </b-row>
            <p-card>
                <b-row>
                    <b-col cols='12' lg='6'>
                        <b-form-group>
                            <slot name="label"><div class="pb-2">Account Type</div></slot>
                            <b-form-radio-group v-model="user.isApiUser" :disabled="!isNew">
                                <b-form-radio :value=false>Application User</b-form-radio>
                                <b-form-radio :value=true>API Key</b-form-radio>                                
                            </b-form-radio-group>
                        </b-form-group>
                                
                        <p-input
                            label='User Name'
                            v-model='user.userName'
                            rules='required|max:256|valid_username'
                            :disabled='!isNew'
                        />
                        <p-input
                            label='Display Name'
                            v-model='user.displayName'
                            rules='required|max:75'
                        />
                        <p-input
                            label='Email'
                            v-model='user.email'
                            rules='required|email|max:254'
                        />                        
                        <p-checkbox
                            label='Is Active?'
                            v-model='user.isActive'
                        />

                        <br v-if="user.isApiUser" >
                        <p-select
                            v-if="user.isApiUser && isNew"    
                            v-model="user.scope"
                            :options="scopes" 
                            label="Scope"                          
                        >
                        </p-select>                        
                        
                        <div v-if='!editPassword' class='text-right'>
                            <b-link @click='showPassword'>Set Password</b-link>
                        </div>
                        
                        <template v-if='editPassword'>
                            <br v-if="!user.isApiUser" >
                            <p-input
                                label='Password'
                                v-model='user.password'
                                type='password'
                                rules='required|confirmed:confirmation|min:7|strong_password'
                            />
                            <p-input
                                label='Confirm Password'
                                v-model='confirmPassword'
                                type='password'
                                vid='confirmation'
                            />
                            <h5 class='text-muted'>
                                Passwords must be at least 7 characters and
                                contain at least: 1 letter and 1 number
                            </h5>
                        </template>

                        <div v-if="user.isApiUser">
                            <br>
                            <b-row align-v="end">
                                <b-col xs="12" xl="8">
                                    <p-input
                                        label='Api Key'
                                        v-model="user.apiKey"
                                        rules='required'
                                        disabled
                                    />
                                </b-col>
                                <b-col class="pb-3" cols="4">
                                    <b-button block variant="primary" @click="getNewApiKey">Generate</b-button>
                                </b-col>
                            </b-row>
                        </div>
                    </b-col>
                </b-row>
            </p-card>
            <p-card>
                <b-row>
                    <b-col>
                        <h3>Security Roles</h3>
                        <p>User will need to log out and back in for changes to take effect</p>
                    </b-col>
                </b-row>
                <br>
                <b-row>
                    <b-col>
                        
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-form-checkbox-group v-model="selectedRoles" :options="roles" value-field="id" text-field="name" stacked >
                        </b-form-checkbox-group>                          
                    </b-col>
                </b-row>

            </p-card>

            <b-row>
                <b-col class=' mb-3 text-center'>
                    <b-button variant='primary' type='submit'>
                        Save
                    </b-button>
                </b-col>
            </b-row>
            
        </div>
    </p-form>
    </p-secure>
</template>

<script>
import axios from 'axios';
import NavigationGuard from '@/components/mixins/NavigationGuard.js';

export default{
    mixins: [NavigationGuard],
    props: {
        id: String        
    },
    data() {
        return {
            user: null,
            confirmPassword: '',
            editPasswordClicked: false,
            roles: [],
            selectedRoles: [],
            scopes: ["prism", "hyperspace"]
        };
    },
    watch: {
        user: NavigationGuard.$watcher,
        selectedRoles: NavigationGuard.$watcher,
        $route: function(to, from) {
            if (to !== from) {
                this.loadData();
            }
        }
    },
    computed: {
        isNew() {
            return this.id === 'new';
        },
        editPassword() {
            return this.isNew || this.editPasswordClicked;
        }
    },
    methods: {
        onSubmit() {
            if(!this.user.isApiUser && !this.user.scope == 'hyperspace'){
                if(!this.selectedRoles || this.selectedRoles.length == 0){
                    this.$bvModal.msgBoxOk('Please select at least one security role.');
                    return;
                }
            }

            //if Admin role is being added, add a confirmation to make sure they want to do this
            var adminRole = this.roles.find(r => r.name == 'Admin');
            if(adminRole && (this.selectedRoles.includes(adminRole.id) && !this.user.roles.includes(adminRole.id))){
                this.$bvModal
                    .msgBoxConfirm('You are making this user an Administrator that has unrestricted access to all modules, including the ability to modify other user permissions. Are you sure?')
                    .then(value => {
                        if(value){
                            this.saveUser();
                        }
                    })
            }
            else{
                this.saveUser();
            }            
        },
        saveUser(){
            this.user.roles = this.selectedRoles;
            axios.post('users', this.user).then(response => {
                this.dataSaved();
                if (this.isNew) {
                    this.$router.push('/users/' + response.data);
                }
                this.$toasted.global
                    .app_success(
                        `User '${this.user.userName}' saved successfully.`
                    )
                    .goAway(5000);
            });
        },
        getNewApiKey(){
            axios.get('users/newapikey').then(res => {
                this.user.apiKey = res.data;
            })
        },
        showPassword() {
            this.editPasswordClicked = true;
        },
        deleteUser() {
            this.$bvModal
                .msgBoxConfirm('Are you sure you want to delete this user?')
                .then(value => {
                    if (value) {
                        axios.post('users/delete', this.user).then(resp => {
                            if (resp) {
                                this.dataSaved();
                                this.$toasted.global
                                    .app_success(
                                        `${this.user.userName} deleted successfully.`
                                    )
                                    .goAway(5000);
                                this.$router.push('/users');
                            }
                        });
                    }
                });
        },
        loadData() {
            //roles
            axios.get('roles')
                .then(r => {
                    this.roles = r.data.sort(function(a, b){
                        if(a.name < b.name) { return -1; }
                        if(a.name > b.name) { return 1; }
                        return 0;
                    });}
                );

            //user
            let promise = this.isNew
                ? axios.get('users/new')
                : axios.get('users', { params: { id: this.id } });
            promise.then(resp => {
                this.user = resp.data;
                this.selectedRoles = resp.data.roles;
                 if (!this.isNew) {
                         this.dataLoaded();
                    }
            });
        }
    },
    mounted: function() {
        this.loadData();
    }
};
</script>
