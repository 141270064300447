<template>
    <div v-if="term" :key="term.termId">
        <p-form @submit="onSubmit">
            <div class="container-fluid content-wrapper">
                <b-row class="content-heading">
                    <b-col>
                        <div>{{ isNew ? 'Add' : 'Edit' }} Term</div>
                    </b-col>
                    <b-col cols="auto" class="ml-auto">
                        <p-button
                            type="submit"
                            variant="primary"
                            :is-busy="isBusy"
                            >Save</p-button
                        >
                    </b-col>
                </b-row>
                <b-tabs>
                    <b-tab title="General">
                        <p-card v-if="isNew">
                            <b-row cols="1" cols-sm="2" cols-xl="3">
                                <b-col>
                                    <p-select
                                        label="Campus"
                                        data-type="campuses"
                                        v-model="newTerm.campusId"
                                        rules="required"
                                        @input="loadNewSequence(true)"
                                    />
                                </b-col>
                                <b-col>
                                    <p-datepicker
                                        label="Class Start Date"
                                        v-model="newTerm.startDate"
                                        rules="required"
                                        @date-picker-initialized="e => {dataLoaded()}"
                                    />
                                </b-col>
                                <b-col>
                                    <p-input
                                        label="Description"
                                        v-model="newTerm.description"
                                        rules="max:80|required"
                                    />
                                </b-col>
                                <b-col>
                                    <p-input
                                        label="Year"
                                        placeholder="YYYY"
                                        v-model.number="newTerm.year"
                                        rules="required|p-integer|min_value:1800|max_value:9999"
                                        type="number"
                                        @input="loadNewSequence(false)"
                                    />
                                </b-col>
                                <b-col>
                                    <p-input
                                        label="Sequence"
                                        placeholder="(eg A, B, C )"
                                        v-model="newTerm.sequenceLetter"
                                        rules="required|max:1|"
                                    />
                                </b-col>
                            </b-row>
                        </p-card>
                        <div v-else>
                            <b-row>
                                <b-col cols="auto" class="mb-2">
                                    <b-overlay
                                        :show="isBusy"
                                        rounded
                                        opacity="0.5"
                                        spinner-small
                                        spinner-variant="primary"
                                        class="d-inline-block"
                                    >
                                        <b-dropdown
                                            class="mr-1"
                                            text="Export PDF"
                                            variant="outline-primary"
                                        >
                                            <b-dropdown-item v-b-modal.exportPriceListModal>Price List</b-dropdown-item>
                                            <b-dropdown-item v-b-modal.exportCourseListModal>Course List</b-dropdown-item>
                                            <b-dropdown-item v-b-modal.exportAuthorListModal>Author List</b-dropdown-item>
                                        </b-dropdown>
                                    </b-overlay>
                                    <p-button
                                        v-if="isOpen"
                                        :is-busy="isBusy"
                                        @click="closeTermClick"
                                        variant="outline-danger"
                                        >Close Term</p-button
                                    >
                                </b-col>
                            </b-row>
                            <p-card>
                                <b-row cols="1" cols-sm="2" cols-xl="4">
                                    <b-col>
                                        <p-select
                                            label="Campus"
                                            data-type="campuses"
                                            v-model="term.campusId"
                                            disabled
                                        />
                                    </b-col>
                                    <!-- push the start/end dates onto same line on small screens -->
                                    <b-col class="d-xl-none"></b-col>
                                    <b-col>
                                        <p-datepicker
                                            label="Class Start Date"
                                            v-model="
                                                term.locations[0].startDate
                                            "
                                            :disabled="isClosed"
                                            vid="termsStartDate"
                                            rules="required"
                                            @date-picker-initialized="e => {dataLoaded()}"
                                        />
                                    </b-col>
                                    <b-col>
                                        <p-datepicker
                                            label="Class End Date"
                                            v-model="
                                                term.locations[0].closeOnDate
                                            "
                                            rules="dateGreaterOrEqual:@termsStartDate"
                                            :disabled="isClosed"
                                            @date-picker-initialized="e => {dataLoaded()}"
                                        />
                                    </b-col>
                                    <b-col>
                                        <p-input
                                            label="Name"
                                            v-model="term.name"
                                            readonly
                                        />
                                    </b-col>
                                    <b-col>
                                        <p-input
                                            label="Status"
                                            :value="formatStatus"
                                            readonly
                                        />
                                    </b-col>
                                    <b-col>
                                        <p-input
                                            label="Description"
                                            v-model="term.description"
                                            rules="max:80|required"
                                            :disabled="isClosed"
                                        />
                                    </b-col>
                                    <b-col>
                                        <p-datepicker
                                            label="Reservation End"
                                            v-model="
                                                term.locations[0]
                                                    .reservationEndDate
                                            "
                                            :disabled="isClosed"
                                            @date-picker-initialized="e => {dataLoaded()}"
                                        />
                                    </b-col>
                                    <b-col class="d-none d-xl-block"> </b-col>
                                    <b-col>
                                        <p-select
                                            label="Buyback To Subtract"
                                            v-model="
                                                term.locations[0]
                                                    .buybackToSubtract
                                            "
                                            data-type="buybackToSubtractOptions"
                                            text-field="value"
                                            :disabled="isClosed"
                                        />
                                    </b-col>
                                    <b-col>
                                        <p-select 
                                            label="Returned Rentals to Subtract"
                                            v-model="
                                                term.locations[0]
                                                    .returnedRentalsToSubtract
                                            "
                                            data-type="returnedRentalsToSubtractOptions"
                                            text-field="value"
                                            :disabled="isClosed || !term.rentalPeriod.isStoreManaged"
                                        />
                                    </b-col>
                                    <b-col>
                                        <p-checkbox
                                            label="Subtract Order Short"
                                            v-model="
                                                term.locations[0]
                                                    .subtractOrderShort
                                            "
                                            class="pt-3 pb-3"
                                            :disabled="isClosed"
                                        />
                                    </b-col>
                                </b-row>
                                <b-row cols="1" cols-sm="2" cols-xl="4">
                                    <b-col>
                                                <p-select 
                                                    label="Online Sharing"
                                                    :options="webSalesOptions"
                                                    v-model="term.locations[0].publishOnline"
                                                />
                                    </b-col>
                                    <b-col>
                                        <p-datepicker
                                            label="Online Sharing Start Date"
                                            v-model="
                                                term.locations[0]
                                                    .publishOnlineStartDate
                                            "
                                            :disabled="
                                                isClosed ||
                                                    term.locations[0]
                                                        .publishOnline === 0
                                            "
                                            vid="publishOnlineStartDate"
                                            @date-picker-initialized="e => {dataLoaded()}"
                                        />
                                    </b-col>
                                    <b-col>
                                        <p-datepicker
                                            label="Online Sharing End Date"
                                            v-model="
                                                term.locations[0]
                                                    .publishOnlineEndDate
                                            "
                                            :disabled="
                                                isClosed ||
                                                    term.locations[0]
                                                        .publishOnline === 0
                                            "
                                            rules="dateGreaterOrEqual:@publishOnlineStartDate"
                                            @date-picker-initialized="e => {dataLoaded()}"
                                        />
                                    </b-col>
                                </b-row>
                                <p-card
                                    v-if="isOpen"
                                    title="Post Sales"
                                    class="mb-2 pl-2 py-2"
                                >
                                    <b-row>
                                        <b-col cols="12" xl="9">
                                            <p-dragable
                                                numbered
                                                :key="
                                                    term.nonPostingTermLocations ==
                                                    null
                                                        ? -1
                                                        : term
                                                              .nonPostingTermLocations
                                                              .length
                                                "
                                                v-model="
                                                    term.termLocationPostingOrder
                                                "
                                                :label="
                                                    postedOrderLength > 1
                                                        ? 'Active Terms - Drag to order post priority'
                                                        : 'Active Terms'
                                                "
                                                :additional-options-label="
                                                    'Inactive Terms - Move terms to be posted into the left list'
                                                "
                                                :additional-options="
                                                    term.nonPostingTermLocations
                                                "
                                                :footer="
                                                    postedOrderLength
                                                        ? ''
                                                        : 'Drag terms here to designate them for posting'
                                                "
                                                top-message="(Top post priority)"
                                            ></p-dragable>
                                        </b-col>
                                    </b-row>
                                </p-card>

                                <p-card>
                                    <b-row>
                                        <b-col cols="12" class="d-flex mb-3">
                                            <p-table
                                                ref="childComponent"
                                                :items="academicDepartments"
                                                :fields="fields"
                                                :per-page="500"
                                                class="wide"
                                                @input="departmentsChanged"
                                                v-model="
                                                    term.locations[0]
                                                        .locationDetails
                                                "
                                                :is-selected-function="
                                                    isSelected
                                                "
                                            >
                                                <template
                                                    v-slot:cell(qtpPercentage)="{
                                                        value,
                                                        item
                                                    }"
                                                >
                                                    <p-number
                                                        v-model.number="
                                                            item.qtpPercentage
                                                        "
                                                        compact-format
                                                        input-type="percent"
                                                        name="Allocation Percentage"
                                                        rules="required|min_value:0"
                                                    />
                                                </template>
                                            </p-table>
                                        </b-col>
                                    </b-row>
                                </p-card>
                            </p-card>
                        </div>
                    </b-tab>
                    <p-feature-flag name="Rentals">
                        <b-tab title="Rentals" v-if="!isNew">
                            <b-row>
                                <b-col>
                                    <h4>Rental Sources</h4>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="auto">
                                    <p-checkbox
                                        :use-switch="true"
                                        label="Enable Rentals"
                                        v-model="term.rentalPeriod.isStoreManaged"
                                        :disabled="!isOpen || noRentalAgreement"
                                        class="text-right"
                                        size="md"
                                        @change="isStoreManagedChecked()"
                                    >
                                    </p-checkbox>
                                </b-col>
                                <b-col cols="auto">
                                    <p-input
                                        label="Rental Agreement"
                                        v-model="term.rentalAgreement"
                                        :disabled="true"
                                    />
                                </b-col>
                                <b-col>
                                    <div v-if="noRentalAgreement" class="mt-3 pt-3" style="color:red;">
                                        Rental Agreement must be setup in Store Setup Parameters before you can proceed.
                                    </div>
                                </b-col>
                            </b-row>
                            <b-row class="mt-4">
                                <b-col>
                                    <h4>Rental Term Setup</h4>
                                </b-col>
                            </b-row>
                            <b-row cols="1" cols-md="2" cols-lg="3">
                                <b-col>
                                    <p-datepicker
                                        label="First Day to Rent"
                                        name="First Day to Ren"
                                        v-model="term.rentalPeriod.startDate"
                                        vid="startDate"
                                        @date-picker-initialized="e => {dataLoaded()}"
                                        :rules="isRequired"
                                        :disabled="!isOpen || !isRequired || noRentalAgreement"
                                    />
                                </b-col>
                                <b-col>
                                    <p-datepicker
                                        label="Last Day to Rent"
                                        name="Last Day to Rent"
                                        v-model="term.rentalPeriod.endDate"
                                        vid="endDate"
                                        @date-picker-initialized="e => {dataLoaded()}"
                                        :rules="
                                            isRequired +
                                                '|dateGreaterOrEqual:@startDate'
                                        "
                                        :disabled="!isOpen || !isRequired || noRentalAgreement"
                                    />
                                </b-col>
                                <b-col>
                                    <p-datepicker
                                        label="Full Refund Expiration Date"
                                        name="Full Refund Expiration Date"
                                        v-model="
                                            term.rentalPeriod.refundExpDate
                                        "
                                        vid="refundExpDate"
                                        @date-picker-initialized="e => {dataLoaded()}"
                                        :rules="
                                            isRequired +
                                                  '|dateGreater:@startDate'
                                                +
                                                 '|dateLessThan:@dueDate'
                                        "
                                        :disabled="!isOpen || !isRequired || noRentalAgreement"
                                    />
                                </b-col>

                                <b-col>
                                    <p-datepicker
                                        label="Due Date"
                                        name="Due Date"
                                        v-model="term.rentalPeriod.dueDate"
                                        vid="dueDate"
                                        @date-picker-initialized="e => {dataLoaded()}"
                                        :rules="
                                            isRequired + '|dateGreater:@endDate'
                                        "
                                        :disabled="!isOpen || !isRequired || noRentalAgreement"
                                    />
                                </b-col>
                                <b-col>
                                    <p-number
                                        input-type="percent"
                                        label="Replacement Price - % of New Price"
                                        name="Replacement Price"
                                        v-model="
                                            term.rentalPeriod
                                                .replacementPricePercentage
                                        "
                                        :rules="isRequired + '|min_value:0'"
                                        :disabled="!isOpen || !isRequired || noRentalAgreement"
                                    />
                                </b-col>
                                <b-col>
                                    <p-number
                                        input-type="money"
                                        label="Default Late Fee"
                                        name="Default Late Fee"
                                        v-model="
                                            term.rentalPeriod.defaultLateFee
                                        "
                                        :rules="isRequired + '|min_value:0'"
                                        :disabled="!isOpen || !isRequired || noRentalAgreement"
                                    />
                                </b-col>
                            </b-row>
                        </b-tab>
                    </p-feature-flag>
                </b-tabs>
                <b-row>
                    <b-col class="mb-3 mt-2 text-center">
                        <p-button
                            variant="primary"
                            type="submit"
                            :is-busy="isBusy"
                         >
                            Save
                         </p-button
                        >
                    </b-col>
                </b-row>
            </div>
        </p-form>

        <p-modal
            ok-only
            label="Past Open Terms"
            ref="closePastTermsFirstModal"
            name="closePastTermsFirstModal"
        >
            Past open terms must be closed before this term can be closed.
            <br /><br />Please close the following term(s):
            <div v-for="item in pastOpenTerms" :key="item.name">
                <b-link @click="switchToTerm(item.termId)">
                    {{ item.name }}
                </b-link>
                <br />
            </div>
        </p-modal>

        <p-modal
            label="Close Term"
            size="md"
            no-close-on-backdrop
            ref="closeTermModal"
            hide-footer
        >
            <p-form id="close-form" @submit="submitClose">
                <b-row cols="1">
                    <b-col>
                        <p-checkbox
                            label="Compute and replace allocated inventory for the next term?"
                            v-model="
                                closeTermSettings.replaceAllocatedInventoryForNextTerm
                            "
                        />
                    </b-col>
                    <b-col>
                        <p-modal
                            label="Reallocation Report"
                            class="ml-4"
                            button-label="View Report"
                            ok-only
                            small-button
                            button-variant="light"
                            size="xl"
                            name="reallocationReportModal"
                            ><p-table
                                :items="reallocationDetails"
                                sort-by="author"
                                :enable-row-selection="false"
                                :fields="reallocationFields"
                            ></p-table
                        ></p-modal>
                    </b-col>
                    <b-col>
                        <p-checkbox
                            class="mt-3"
                            :disabled="
                                closeTermSettings.replaceAllocatedInventoryForNextTerm
                            "
                            label="Reset status of Order Decisions to rework?"
                            v-model="
                                closeTermSettings.setOrderDecisionsToRework
                            "
                        />
                    </b-col>
                    <b-col>
                        <p-checkbox
                            class="mt-3"
                            label="Remove Associated Want Lists?"
                            v-model="closeTermSettings.removeWantLists"
                        />
                    </b-col>
                    <b-col class="mt-3 text-center">
                        <p-button
                            variant="outline-primary"
                            :is-busy="isBusy"
                            class="mb-1"
                            type="submit"
                            form="close-form"
                            >Close Term</p-button
                        ><br />
                        (Can't undo this)
                    </b-col>
                </b-row>
            </p-form>
        </p-modal>

        <!--<p-modal @show="openCreateModal = {} "
                 id="OpenCreateModal"
                 size="sm"
                 name="OpenCreateModal"
                 label="Term"
                 hide-footer>
            <template>
                <b-row>
                    <b-col class="ml-2 mb-2">
                        <b-button variant='outline-primary' @click="postNewTerm">Create Term</b-button>
                    </b-col>
                    <b-col class="mr-2 mb-2">
                        <b-button variant='outline-primary' @click="openCopyTerm">Copy Term</b-button>
                    </b-col>
                </b-row>
            </template>
        </p-modal>-->
        <!--<p-modal @show="openCopyModal = {} "
                 id="OpenCopyModal"
                 name="OpenCopyModal"
                 label=" Copy From Term"
                 hide-footer>
            <template>
                <copyTerm :term="term" :newTerm="newTerm" @ok="postNewTerm"></copyTerm>
            </template>
        </p-modal>-->
        <PriceListReport :term-id="term.termId"></PriceListReport>
        <CourseListReport :term-id="term.termId"></CourseListReport>
        <AuthorListReport :term-id="+id" :campus-id="term.campusId" />
    </div>
</template>

<script>


import axios from 'axios';
import NavigationGuard from '@/components/mixins/NavigationGuard.js';
import selectListOptionsDataContext from '@/services/selectListOptions.dataContext.js';
import unionBy from 'lodash/unionBy';

import AuthorListReport from '@/views/Reports/Terms/AuthorList.vue';
import CourseListReport from '@/views/Reports/Terms/CourseList.vue';
import PriceListReport from '@/views/Reports/Terms/PriceList.vue';

//import copyTerm from '@/views/Terms/CopyTerm.vue';

export default {
    mixins: [NavigationGuard],
    props: {
        id: [String, Number],
        isFirst: Boolean
    },
    data() {
        return {
            isBusy: false,
            reallocationDetails: null,
            pastOpenTerms: null,
            newTerm: {
                campusId: null,
                startDate: null,
                year: null,
                sequenceLetter: null
                //copyTerm: {
                //    fromTermId: null,
                //    academicDepartment: false,
                //    percentage: false,
                //    course: false,
                //    sections: false,
                //    disabledSection: false,
                //    courseRequest: false
                //},
            },
            fields: [
                {
                    key: 'departmentName',
                    label: 'Departments',
                    sortable: true,
                    automaticSearch: true
                },
                {
                    key: 'qtpPercentage',
                    label: 'Allocation Percentage',
                    sortable: false,
                    automaticSearch: true
                }
            ],
            term: null,
            pageId: this.id,
            reallocationFields: [
                {
                    key: 'sku',
                    sortable: true
                },
                {
                    key: 'isbn',
                    sortable: true
                },
                {
                    key: 'author',
                    sortable: true,
                    label: 'Author/Title or Description'
                },
                {
                    key: 'currentAllocation',
                    thClass: 'text-center',
                    tdClass: 'text-right',
                    sortable: true
                },
                {
                    key: 'newAllocation',
                    thClass: 'text-center',
                    tdClass: 'text-right',
                    sortable: true
                },
                {
                    key: 'newOpenToBuy',
                    thClass: 'text-center',
                    tdClass: 'text-right',
                    sortable: true
                },
                {
                    key: 'nextTerm',
                    sortable: true
                }
            ],
            closeTermSettings: {
                replaceAllocatedInventoryForNextTerm: true,
                setOrderDecisionsToRework: false,
                removeWantLists: true
            },
            academicDepartments: null,
            disableIsNbc: false,
            disableIsStoreManaged: false,
            
        };
    },
    watch: {
        term: NavigationGuard.$watcher,
        'term.locations': function() {
            if (this.term && this.isFirst && this.$refs.childComponent) {
                this.$refs.childComponent.toggleSelectAll();
            }
        },
        newTerm: NavigationGuard.$watcher,
        'newTerm.sequenceLetter': async function(sequenceLetter) {
            if (sequenceLetter) {
                await axios.get('terms/checkSequenceExist/', {
                    params: {
                        campusId: this.newTerm.campusId,
                        sequenceLetter: sequenceLetter
                    }
                });
            }
            return;
        },
        $route() {
            this.$refs.closePastTermsFirstModal?.hide();
            this.loadTerm();
        }
    },
    computed: {
        isNew() {
            return this.pageId === 'new';
        },
        formatStatus() {
            return this.isOpen ? 'Open' : 'Closed';
        },
        isOpen() {
            return this.term.locations[0].isOpen;
        },
        isClosed() {
            return !this.isOpen;
        },
        postedOrderLength() {
            if (this.term.termLocationPostingOrder == null) {
                return 0;
            }
            return this.term.termLocationPostingOrder.length;
        },
        isRequired() {
            return this.term.rentalPeriod.isNbc ||
                this.term.rentalPeriod.isStoreManaged
                ? 'required'
                : '';
        },
        noRentalAgreement()
        {
            return this.term.rentalAgreement == '';
        },
        webSalesOptions()
        {
            if(this.isClosed){
                return [
               { value: 0, text: 'None' },
               { value: 3, text: 'Closed' }    
            ];
            }
            return [
               { value: 0, text: 'None' },
               { value: 1, text: 'Sales Open' },
               { value: 2, text: 'View Only' },
               { value: 3, text: 'Closed' }   
            ];
        }
    },
    methods: {
        isNbcChecked() {
            if (this.term.rentalPeriod.isNbc) {
                this.$bvModal
                    .msgBoxConfirm(
                        'By checking this box you are agreeing to the Mosaic Rental Program terms and box cannot be unchecked.'
                    )
                    .then(value => {
                        if (value) {
                            this.disableIsNbc = true;
                        } else {
                            this.term.rentalPeriod.isNbc = false;
                        }
                    });
            }
        },
        isStoreManagedChecked() {
            if(this.term.hasRentals){
                 this.$toasted.global
                    .app_error(
                        `Cannot disable. Term has rentals associated with it`
                    )
                    .goAway(5000);
                     setTimeout(() => {
                     this.term.rentalPeriod.isStoreManaged=true;
                     },200);
                    return;
            }
        },
        async loadNewSequence(updateYear) {
            //year is calculated based on the campus if not provided
            if (!this.newTerm.campusId) {
                //hold off getting sequence if campus not selected yet
                return;
            }
            let resp = await axios.get('terms/getNewData/', {
                params: {
                    campusId: this.newTerm.campusId,
                    year: updateYear === true ? null : this.newTerm.year
                }
            });

            this.newTerm.sequenceLetter = resp.data.sequenceLetter;
            if (updateYear === true) {
                //only updating year on campus change (not when the user updates the year)
                this.newTerm.year = resp.data.year;
            }
        },
        async submitClose() {
            this.isBusy = true;
            try {
                await axios.post('terms/close', {
                    termId: this.term.termId,
                    ...this.closeTermSettings
                });
                this.$toasted.global
                    .app_success(
                        `Term '${this.term.name}' closed successfully.`
                    )
                    .goAway(5000);
                await this.loadTerm();
            } finally {
                this.isBusy = false;
            }

            this.$refs.closeTermModal.hide();
        },
        switchToTerm(termId) {
            this.$refs.closePastTermsFirstModal.hide();
            this.pastOpenTerms = null;
            this.reallocationDetails = null;
            this.term = null;
            this.$router.push(`/terms/${termId}`);
        },
        async closeTermClick() {
            if (this.reallocationDetails === null) {
                this.isBusy = true;
                var resp = await axios.get('terms/termclosingdata/', {
                    params: {
                        id: this.term.termId
                    }
                });
                this.pastOpenTerms = resp.data.pastOpenTerms;
                this.reallocationDetails = resp.data.reallocationDetails;
                this.isBusy = false;
            }
            if (this.pastOpenTerms.length > 0) {
                this.$refs.closePastTermsFirstModal.show();
                return;
            }
            this.$refs.closeTermModal.show();
        },
        isSelected(item) {
            return this.term.locations[0].locationDetails.some(
                x => x.courseDepartmentId == item.courseDepartmentId
            );
        },
        async onSubmit() {
            if (this.isNew) {
                try {
                    await axios.get('terms/checkSequenceExist/', {
                        params: {
                            campusId: this.newTerm.campusId,
                            sequenceLetter: this.newTerm.sequenceLetter,
                            year: this.newTerm.year
                        }
                    });
                } catch {
                    return;
                }
                this.newTerm.name =
                    this.newTerm.year + this.newTerm.sequenceLetter;
                this.newTerm.locations = [
                    {
                        startDate: this.newTerm.startDate,
                        isOpen: true,
                        buybackToSubtract: 'None',
                        returnedRentalsToSubtract: 'None'
                    }
                ];
                this.postNewTerm();
            } else {
                axios.post('terms', this.term).then(resp => {
                    this.term.rentalPeriod.rentalPeriodId =
                        resp.data.rentalPeriod ? resp.data.rentalPeriod.rentalPeriodId : null;
                    this.$toasted.global
                        .app_success(
                            `Term '${this.term.name}' saved successfully.`
                        )
                        .goAway(5000);
                        this.dataLoaded();
                });
            }
        },
        departmentsChanged(values) {
            //set any selected departments QTP Percentage to 100 where it has not been set away from 0.
            values
                .filter(x => x.qtpPercentage === 0)
                .forEach(x => (x.qtpPercentage = 100));

            //set any de-selected departments QTP Percentage to 100
            this.academicDepartments
                .filter(
                    x =>
                        !values.some(
                            y => y.courseDepartmentId === x.courseDepartmentId
                        )
                )
                .forEach(x => (x.qtpPercentage = 0));
        },
        updateDepartmentList: async function() {
            this.academicDepartments = JSON.parse(
                JSON.stringify(
                    (
                        await selectListOptionsDataContext.getStoreDropdownData(
                            'courseDepartments'
                        )
                    ).filter(x => x.campusId == this.term.campusId)
                )
            ).map(x => {
                return {
                    qtpPercentage: 0,
                    courseDepartmentId: x.value,
                    checked: this.term.locations[0].locationDetails.some(
                        d => d.courseDepartmentId === x.value
                    ),
                    departmentName: x.text
                };
            });
            this.academicDepartments = unionBy(
                this.term.locations[0].locationDetails,
                this.academicDepartments,
                'courseDepartmentId'
            );
        },
        setActivePostingTerm(termLocations) {
            if (termLocations == null) {
                return;
            }
            termLocations
                .filter(
                    x =>
                        x.termLocationId ===
                        this.term.locations[0].termLocationId
                )
                .forEach(x => {
                    x.active = true;
                });
        },
        //openCopyTerm() {
        //    this.$bvModal.hide('OpenCreateModal');
        //    this.$bvModal.show('OpenCopyModal');
        //},
        postNewTerm() {
            axios.post('terms', this.newTerm).then(async response => {
                this.term = response.data;
                this.pageId = this.term.termID;
                this.updateDepartmentList();
                this.setActivePostingTerm(this.term.termLocationPostingOrder);
                this.setActivePostingTerm(this.term.nonPostingTermLocations);
                this.dataLoaded();
                this.$router.push({
                    name: 'editterm',
                    params: { isFirst: true, id: response.data.termId }
                });
                this.$toasted.global
                    .app_success(`Term '${this.term.name}' saved successfully.`)
                    .goAway(5000);
                //this.$bvModal.hide('OpenCopyModal');
                //this.$bvModal.hide('OpenCreateModal');
            });
        },
        async loadTerm(termId) {
            let promise = this.isNew
                ? axios.get('terms/new')
                : axios.get('terms', { params: { id: termId ?? this.id } });
            let resp = await promise;
            this.term = resp.data;

            this.setActivePostingTerm(this.term.termLocationPostingOrder);
            this.setActivePostingTerm(this.term.nonPostingTermLocations);
            //this.mockCourseRequest.termId = this.term.termId;

            if (!this.isNew) {
                await this.updateDepartmentList();
            }

            if (!this.isNew) {
                this.dataLoaded();
            }
        },
    },
    mounted: async function() {
        this.loadTerm();
    },
    components: {
        AuthorListReport,
        CourseListReport,
        PriceListReport,
        //copyTerm
    }
};
</script>

<style scoped>
.wide {
    width: 800px;
}
</style>
