<template>
    <p-feature-flag ref="AccountingFlag" name="Accounting">
    <SearchScreen
        title="Invoice Adjustment Reasons"
        api-destination="invoiceadjustmentreason"
        edit-key="invoiceAdjustmentReasonId"
        :filters="filters"
        :fields="fields"
        :filterBy="filterBy"
        :show-set-to="false"
        :use-is-active-checkbox="false"
        add-new-button
    >   
           
        <template v-slot:cell(glCode)="{  }">
            <span>Unassigned</span>
        </template>
    </SearchScreen>
    </p-feature-flag>
</template>

<script>
import SearchScreen from '../../components/SearchScreen.vue';
import SearchStorage from '@/components/mixins/SearchFilterStorage.js';
export default {
    mixins: [SearchStorage],
    components: { SearchScreen },
    data() {
        return {
            isBusy: false,
            filterName: 'InvoiceAdjustmentReasonFilter',
            filters: {
                automatic: '',
                perPage: 100,
                sortBy: 'reason',
                sortDesc: true,                
                searchBy: 'All'
            },
            filterBy: ['All', 'Adjustment Reason', 'Reason Description', 'GL Code'],
            fields: [
                {
                    key: 'reason',
                    sortable: true,
                    label: 'Adjustment Reason'
                },
                {
                    key: 'description',
                    sortable: true,
                    label: 'Reason Description'
                },
                {
                    key: 'ledgerNumbers',
                    sortable: true,
                    label: 'GL Codes'
                },
                {
                    key: 'isActive',
                    label: 'Status',
                    formatter: value => {
                        return value ? 'Active' : 'Inactive';
                    },
                    sortable: true
                }
            ]
        };
    },
    methods: {}
};
</script>