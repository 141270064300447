<template>
    <p-secure >
        <div v-if="account">
            <p-card>

                    <b-row cols="2"  class="ml-1 mb-1">
                        <b-col>
                            <b-button
                                variant="outline-primary"
                                class="mr-2"
                                v-on:click="printScreen()"
                                :disabled="totalRows <= 0 "
                                >Print</b-button
                            >
                            <b-button variant="outline-primary" @click="downloadFile('csv')"
                               :disabled="totalRows <= 0 " >Export (CSV)</b-button
                            >
                        </b-col>
                    </b-row>
        <p-search-table
            api-destination="accountreceivable"
            search-destination="paymentssearch"
            sort-by="CreateDate"
            ref="searchScreen"
            :filters="filters"
            :fields="fields"
            :refreshSearchResults="refreshSearchResults"
            :sort-desc="true"
            :show-per-page-selector="false"
            :show-top-paging="true"
            :show-bottom-paging="true"
            :use-bootstrap-selection="true"
            :per-page="100"
            @total-rows-changed="getTotalRows"
        >
           <template v-slot:above-table>
            <b-row cols="1" cols-md="2" cols-lg="4">
                <b-col>
                        <p-datepicker label="Start Date"
                          v-model="filters.startDate"
                          vid="reportStartDate" />
                        </b-col>
                    <b-col>
            <p-datepicker label="End Date"
                          vid="endDate"
                          is-end-date
                          rules="dateGreaterOrEqual:@reportStartDate"
                          v-model="filters.endDate" />
        </b-col>
    </b-row>
    </template>

    <template v-slot:cell(view)="{ item }">
        <p-button
                        variant="outline-primary"
                        class="ml-2"
                        >View/Distribute</p-button
                    >
    </template>

        </p-search-table>
            </p-card>
        </div>
    </p-secure>
</template>

<script>
import { datetimeToLocalTimeFormatter, currencyFormatter } from '../../components/Common/Formatters.js';
import axios from 'axios';
import { downloadFileToBrowser } from '@/components/Common/BrowserDownload.js';
export default {
    props: {
        customers: Object,
        account: Object
    },
    methods: {
       getTotalRows(value){
          this.totalRows=value
        },
        refreshTable() {
            this.$refs?.searchScreen.refreshSearchResults();
        },
        printScreen(){
            window.print();
        },
        async downloadFile(exportFormat)
        {
         let apiUrl = 'AccountReceivable/exportpayments';
            this.filters.exportFormat = exportFormat;
            this.isBusy = true;
             axios
                .post(
                    apiUrl,
                    {
                       AccountId: this.account.accountId,
                       exportFormat: exportFormat,
                       automatic:this.filters.automatic,
                       searchBy: this.filters.searchBy,
                       startDate: this.filters.startDate,
                       EndDate: this.filters.endDate,

                    },
                    { responseType: 'blob' }
                )
                  .then(result => {
                    downloadFileToBrowser(
                        result.data,
                        exportFormat == 'pdf' ? `AR Account Transactions` : `AR Account Transactions.csv`,
                        exportFormat == 'pdf' ? 'application/pdf' : 'application/csv'
                    );
                    this.$toasted.global
                        .app_success(
                            `AR Accounts report generated successfully.`
                        )
                        .goAway(5000);
                })
                .finally(() => {
                    this.isExporting = false;
                });
        }
    },
    data() {
        return {
            filterName: 'TransactionsFilter',
            selectedCustomers: [],
            isBusy: false,
            totalRows:0,
            filters: {
                automatic: '',
                searchBy: 'All',
                AccountId: this.account.accountId,
                options: ['All','Transaction ID', 'Payment Reference', 'Comment/Reason'],
                startDate: new Date(new Date().setDate(new Date().getDate() - 365)),
                endDate: new Date(),
            },
            fields: [ {
                    label: 'Action',
                    key: 'view'
                },
                {
                    label: 'Transaction ID',
                    key: 'adjustmentNumber',
                    sortable: true
                },
                {
                    label: 'Transaction Date & Time',
                    key: 'createDate',
                    formatter: datetimeToLocalTimeFormatter,
                    sortable: true
                },
                {
                    label: 'Amount',
                    formatter: currencyFormatter,
                    key: 'amount',
                    sortable: true,
                    tdClass: 'text-center'
                },
                {
                    label: 'Undistributed',
                    key: 'distributed',
                    sortable: true
                },
                {
                    label: 'Payment Reference',
                    key: 'referenceNumber',
                    sortable: true
                },
                {
                    label: 'Comment/Reason',
                    key: 'reason',
                    sortable: true,
                }
            ],
            refreshSearchResults: false
        };
    },
    created: function() {
        this.filters.accountId = this.account.accountId;
    }
};
</script>
