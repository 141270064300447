<template>
    <div class="container-fluid content-wrapper">
        <BookVariantSummaries
            :variant-options="bookVariants"
            :product="product"
            @addVariant="addVariant"
            :show-add-button="true"
            :is-new="isNew"
            :is-busy="isBusy"
        ></BookVariantSummaries>
        <p-card title="Book Details">
            <b-row>
                <b-col cols="12" xl="6">
                    <p-input
                        label="Author"
                        v-model="product.author"
                        rules="required|max:200"
                    />
                </b-col>
                <b-col
                    v-for="v in bookVariants"
                    :key="v"
                    cols="12"
                    sm="4"
                    xl="2"
                >
                    <p-number
                        v-if="product[`${v}Variant`]"
                        :label="`${v} Selling Price`"
                        v-model.number="product[`${v}Variant`].retail"
                        :rules="
                            product[`${v}Variant`] ? 'required|min_value:0' : ''
                        "
                        input-type="money"
                        @change="calculateMargin(product[`${v}Variant`])"
                        left-align
                    >
                        <template v-slot:append>
                            <PriceCalculator
                                :name="`${v}Calculator`"
                                :initial-values="product[`${v}Variant`]"
                                :parameters="parameters"
                            ></PriceCalculator>
                           
                        </template>
                    </p-number>
                    <p-number
                        v-else
                        :disabled="true"
                        :label="`${v} Selling Price`"
                    ></p-number>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12">
                    <p-input
                        label="Title"
                        v-model="product.title"
                        rules="required|max:200"
                    />
                </b-col>
            </b-row>
            <b-row cols="1" cols-sm="2" cols-md="3" cols-xl="5">
                <b-col>
                    <p-input
                        label="ISBN"
                        v-model="product.isbn"
                        :rules="{
                            isbnValid: { isValid: isIsbnValid },
                            zeroThroughNineOrX: true,
                            max: { length: 20 }
                        }"
                        mode="lazy"
                        @change="isbnChange($event, true, true)"
                    />
                </b-col>

                <b-col>
                    <p-input
                        label="Edition"
                        v-model="product.edition"
                        rules="max:20"
                    />
                </b-col>
                <b-col>
                    <p-input
                        label="Copyright"
                        v-model="product.copyright"
                        rules="max:10"
                    />
                </b-col>
                <b-col>
                    <p-select
                        data-type="bindings"
                        label="Binding"
                        v-model="product.bindingId"
                    />
                </b-col>
                <b-col>
                    <p-input
                        label="Imprint"
                        v-model="product.imprint"
                        rules="max:20"
                    />
                </b-col>
            </b-row>
            <b-row cols="1" cols-sm="2" cols-lg="4">
                <b-col>
                    <p-advanced-select
                        data-type="suppliers"
                        label="Supplier"
                        v-model="product.supplierId"
                        @change="loadSupplierDiscounts()"
                        rules="required"
                    />
                </b-col>
                <b-col>
                    <p-select
                        label="Supplier Discounts"
                        :options="supplierDiscountCodes"
                        v-model="product.vendorDiscountCodeId"
                        text-field="text"
                        add-empty-option
                    />
                </b-col>
                <b-col>
                    <p-select
                        data-type="textStatus"
                        label="Status"
                        v-model="product.textStatusId"
                    />
                </b-col>
                <b-col>
                    <p-datepicker
                        label="Status Date"
                        v-model="product.textStatusDate"
                    />
                </b-col>
            </b-row>
            <b-row cols="1">
                <b-col>
                    <p-input
                        label="Comment"
                        v-model="product.comment"
                        rules="max:200"
                    />
                </b-col>
            </b-row>
        </p-card>

        <b-overlay :show="loadingVariant" rounded="sm">
            <BookVariant
                v-if="product.newVariant"
                type="new"
                :is-new="isNew"
                :parameters="parameters"
                :variant="product.newVariant"
                @removeVariant="removeVariant"
                @sohModal="showSOHModal"
                @openBarcodes="openBarcodes($event)"
                @deleteVariant="
                    $emit('deleteProduct', {
                        variant: product.newVariant,
                        type: 'new'
                    })
                "
                @dccChanged="dccChanged($event, 'new')"
                ref="newVariant"
                @savePage="savePage"
            />
            <EmptyBookVariant
                v-else
                type="new"
                @addVariant="addVariant"
                :show-add-button="true"
            />
            <BookVariant
                v-if="product.usedVariant"
                :parameters="parameters"
                type="used"
                :is-new="isNew"
                :variant="product.usedVariant"
                @sohModal="showSOHModal"
                @openBarcodes="openBarcodes($event)"
                @removeVariant="removeVariant"
                @deleteVariant="
                    $emit('deleteProduct', {
                        variant: product.usedVariant,
                        type: 'used'
                    })
                "
                @dccChanged="dccChanged($event, 'used')"
                ref="usedVariant"
                @savePage="savePage"
            />
            <EmptyBookVariant
                v-else
                type="used"
                @addVariant="addVariant"
                :show-add-button="true"
                :hasNew="product.newVariant !== null"
            />
            <BookVariant
                v-if="product.tradeVariant"
                :parameters="parameters"
                type="trade"
                :is-new="isNew"
                :variant="product.tradeVariant"
                @sohModal="showSOHModal"
                @openBarcodes="openBarcodes($event)"
                @removeVariant="removeVariant"
                @deleteVariant="
                    $emit('deleteProduct', {
                        variant: product.tradeVariant,
                        type: 'trade'
                    })
                "
                @dccChanged="dccChanged($event, 'trade')"
                ref="tradeVariant"
                @savePage="savePage"
            />
            <EmptyBookVariant
                v-else
                type="trade"
                @addVariant="addVariant"
                :show-add-button="true"
            />
        </b-overlay>
        <BarcodeListModal
            :currentVariant="currentVariant"
            :is-new-variant="isNew"
            @ok="saveBarcode"
        ></BarcodeListModal>

        <StockAdjustment
            :adjustmentData="stockAdjustment"
            @ok="stockAdjustmentSucceeded"
        />
    </div>
</template>

<script>
import axios from 'axios';
import EmptyBookVariant from './EmptyBookVariant';
import BookVariantSummaries from './BookVariantSummaries';
import BookVariant from './BookVariant';
import BarcodeListModal from './BarcodeListModal';
import StockAdjustment from '@/components/StockAdjustment.vue';
import pricing from '@/services/PricingCalculations.js';
import PriceCalculator from './PriceCalculator.vue';
import selectListOptionsDataContext from '@/services/selectListOptions.dataContext.js';

export default {
    props: {
        product: Object,
        parameters: Object,
        isNew: Boolean
    },
    components: {
        BookVariant,
        EmptyBookVariant,
        StockAdjustment,
        PriceCalculator,
        BarcodeListModal,
        BookVariantSummaries
    },
    data() {
        return {
            isBusy: false,
            isIsbnValid: true,
            supplierDiscountCodes: [],
            loadingVariant: false,
            stockAdjustment: {},
            adjustVariant: {},
            currentVariant: { barcodes: [], defaultBarcode: null },
            variantTemplate: {
                sku: null,
                isPhysical: true,
                dccId: null,
                priceTagTypeId: 1,
                taxTypeId: null,
                barcodes: [],
                defaultBarcode: null,
                cost: 0,
                margin: 0,
                retail: 0,
                stockOnHand: 0,
                isBuybackTitle: true,
                isWantlistTitle: true,
                isDiscontinued: false,
                lastDigitalRefreshDate: null,
                digitalStatus: null

            },
            bookVariants: ['new', 'used', 'digital', 'trade'],
            isDigitalIsbnValid: true
        };
    },

    computed: {
    },
    watch: {
        'product.newVariant.retail': function() {
            if (this.product.newVariant === null) {
                return;
            }
            this.newPriceChanged({
                cost: this.product.newVariant.cost,
                retail: this.product.newVariant.retail
            });
        }
    },
    methods: {
        dccChanged: async function(variant) {
            var resp = await axios.get('products/dccdefaults', {
                params: { dccId: variant.dccId }
            });
            if (variant.margin != resp.data.margin) {
                if (resp.data.margin != null) {
                    variant.margin = resp.data.margin;
                    //need to force retail calculation, otherwise, if we do standard upate and margin
                    //is selected for calculation, we would be undoing the marging value we just set.
                    this.calculateRetail(variant);
                    this.$toasted.global
                        .app_success(
                            "Margin updated to the newly selected DCC's default."
                        )
                        .goAway(5000);
                }
            }
        },

        calculateRetail(variant) {
            //if dcc is changed it brings the new default margin.
            //to keep the cost/retail in line we will adjust the price if needed
            variant.retail = pricing.computeRetail(
                variant.cost,
                variant.margin,
                variant.retail,
                this.parameters.roundingTarget
            );
        },
        calculateMargin(variant) {
            variant.margin = pricing.computeMargin(
                variant.cost,
                variant.sellingPrice
            );
        },
        stockAdjustmentSucceeded(data) {
            this.$emit('savePage', null);
            this.adjustVariant.stockOnHand += data.qty;
            this.$emit('clearDirtyFlag');
        },
        saveBarcode(data) {
            this.currentVariant.barcodes = data.barcodes.map(b => ({ ...b }));
            this.currentVariant.defaultBarcode = data.defaultBarcode;
        },
        async showSOHModal(variant) {
            //Use $set for sku so we can watch this object in the stockadjustment component
            if (variant.retail === 0) {
                await this.$bvModal
                    .msgBoxConfirm(
                        'Are you sure you want make a $0 retail stock adjustment?'
                    )
                    .then(value => {
                        if (value) {
                            this.openSOHModal(variant);
                        }
                    });
            } else {
                this.openSOHModal(variant)
            }
        },
        openSOHModal(variant) {
            this.$set(
                this.stockAdjustment,
                'sku',
                variant.sku == 0 ? null : variant.sku
            );
            this.stockAdjustment.cost = variant.cost;
            this.stockAdjustment.retail = variant.retail;

            this.adjustVariant = variant;

            var productInventoryLocations = variant.productInventoryLocations;
            selectListOptionsDataContext.getSelectedLocationIdAsync()
                .then(locationId => {
                    // The productInventory record does not yet exist for this location
                    if(!productInventoryLocations.some(x => x.sku == variant.sku && x.locationId == locationId)) {
                        this.$bvModal
                            .msgBoxConfirm(
                                `Are you sure you wish to create a new product inventory record for SKU ${variant.sku} for the currently selected location?`
                            )
                            .then(value => {
                                if (value) {
                                    this.$bvModal.show('StockAdjustmentModal');
                                }
                            });
                    }
                    else{
                        this.$bvModal.show('StockAdjustmentModal');
                    }
                });
        },
        mounted: async function() {
            this.loadSupplierDiscounts();
        },
        loadSupplierDiscounts() {
            if (this.product.supplierId == null) return;

            let promise = axios.get('suppliers', {
                params: { id: this.product.supplierId }
            });
            promise.then(resp => {
                this.supplierDiscountCodes = resp.data.shippingDiscountCodes;
            });
        },
        isbnChange: async function(event, physical, refresh) {
            await this.validateISBN(event, physical);
            this.setBarcodes(refresh);
        },

        openBarcodes: async function(variant) {
            this.currentVariant = variant;
            this.$bvModal.show('BarcodeListModal');
        },
        async addVariant(type) {
            //loading should be almost instant, but if the store is emmpty there is a delay in loading defaults
            this.loadingVariant = true;

            let variant = { ...this.variantTemplate };

            this.product[`${type}Variant`] = variant;

            if (type === 'new') {
                if (this.parameters.defaultNewDCCId > 0) {
                    variant.dccId = this.parameters.defaultNewDCCId;
                    await this.dccChanged(variant, type);
                }
                variant.margin = this.parameters.newBookDccDefaultMargin ?? 0;
                this.setBarcodes(true);
            }
            if (type === 'used') {
                if (this.parameters.defaultUsedDCCId > 0) {
                    variant.dccId = this.parameters.defaultUsedDCCId;
                    await this.dccChanged(variant, type);
                }
                this.newPriceChanged({
                    cost: this.product.newVariant.cost,
                    retail: this.product.newVariant.retail
                });
                this.setBarcodes(true);
            }
            if (type === 'digital') {
                this.product[`${type}Variant`] = null;

                if (this.product.isbn == null) {
                    this.$toasted.global
                        .app_error(`No physical ISBN present.`)
                        .goAway(5000);
                    return;
                }

                await this.checkForupdate(variant, type);
            }
            if (type === 'trade') {
                if (this.parameters.defaultTradeDCCId > 0) {
                    variant.dccId = this.parameters.defaultTradeDCCId;
                    await this.dccChanged(variant, type);
                }
                variant.isPhysical = true;
                variant.isBuybackTitle = false;
                variant.isWantlistTitle = false;
                this.setBarcodes(true);
            }

            this.loadingVariant = false;
        },
        async checkForupdate(variant, type) {
            this.isBusy = true;
            let isbns = [];
            isbns.push(this.product.isbnDigits);
            let response = await axios.post(
                'courserequests/checkdigital',
                isbns
            );

            if (response.data.length == 0) {
                this.$toasted.global
                    .app_error(`No digital ISBN found.`)
                    .goAway(5000);
                return;
            }

            let refreshData = response.data[0];
            this.product[`${type}Variant`] = variant;

            if (this.parameters.defaultDigitalDCCId > 0) {
                variant.dccId = this.parameters.defaultDigitalDCCId;
                await this.dccChanged(variant, type);
            }

            variant.isPhysical = false;
            variant.isBuybackTitle = false;
            variant.isWantlistTitle = false;
            variant.retail = refreshData.retail;
            variant.lastDigitalRefreshDate = refreshData.lastDigitalRefreshDate;
            variant.digitalStatus = refreshData.digitalStatus;
            variant.digitalIsbn = refreshData.digitalIsbn;
            variant.digitalIsbnDigits = refreshData.digitalIsbnDigits;
            this.product.digitalIsbn = refreshData.digitalIsbn;
            this.product.digitalIsbnDigits = refreshData.digitalIsbnDigits;
            this.isBusy = false;
        },

        newPriceChanged: function(priceObj) {
            if (!this.parameters.updateUsedPricesFromNew) {
                return;
            }
            var usedVariant = this.product.usedVariant;
            if (usedVariant == null) {
                return;
            }
            usedVariant.cost = pricing.roundTo(
                (priceObj.retail * this.parameters.usedCostPercentOfNewPrice) /
                    100,
                this.parameters.roundingTarget
            );
            usedVariant.retail = pricing.roundTo(
                (priceObj.retail *
                    this.parameters.usedRetailPercentOfNewPrice) /
                    100,
                this.parameters.roundingTarget
            );
            usedVariant.margin = pricing.computeMargin(
                usedVariant.cost,
                usedVariant.retail
            );
        },
        removeVariant(type) {
            this.product[`${type}Variant`] = null;
        },
        setBarcodes: async function(refresh) {
            if (
                this.product.isbn != null &&
                this.product.isbn.length > 9 &&
                this.product.isbn.length <= 20
            ) {
                if (this.barcodes == null || refresh) {
                    await axios
                        .get('products/isbnbarcodes', {
                            params: { isbn: this.product.isbn }
                        })
                        .then(resp => {
                            this.barcodes = resp.data;
                        });
                }
                if (this.product.newVariant != null) {
                    this.product.newVariant.barcodes = this.barcodes.newTextbook;
                    this.setDefaultBarcode(this.product.newVariant);
                }
                if (this.product.usedVariant != null) {
                    this.product.usedVariant.barcodes = this.barcodes.usedTextbook;
                    this.setDefaultBarcode(this.product.usedVariant);
                }
                if (this.product.tradeVariant != null) {
                    this.product.tradeVariant.barcodes = this.barcodes.tradebook;
                    this.setDefaultBarcode(this.product.tradeVariant);
                }
            }
        },
        setDefaultBarcode(variant) {
            var defaultBarcode = variant.barcodes.find(x => x.isDefault);
            if (defaultBarcode) {
                variant.defaultBarcode = defaultBarcode.barcode;
            }
        },
        validateISBN: async function(value, physical) {
            if (!value) {
                return;
            }
            if (physical) {
                this.isIsbnValid = false;
            } else {
                this.isDigitalIsbnValid = false;
            }

            let result = await axios.get('products/isbnvalidation', {
                params: { isbn: value }
            });
            if (result && result.data) {
                if (physical) {
                    this.isIsbnValid = true;
                    this.product.isbn = result.data.hyphenatedIsbn;
                } else {
                    this.isDigitalIsbnValid = true;
                    this.product.digitalIsbn = result.data.hyphenatedIsbn;
                }
                if (result.data.newCheckDigit) {
                    const h = this.$createElement;
                    const messageVNode = h('div', [
                        h('p', ['Invalid ISBN Check Digit']),
                        h('p', [
                            `ISBN you provided: ${result.data.hyphenatedIsbn}`
                        ]),
                        h('p', [
                            `ISBN with replaced check digit: ${result.data.newCheckDigit}`
                        ]),
                        h('p', ['Would you like to replace the check digit?'])
                    ]);

                    let answer = await this.$bvModal.msgBoxConfirm(
                        [messageVNode],
                        {
                            noCloseOnBackdrop: true,
                            okTitle: 'Yes',
                            cancelTitle: 'No'
                        }
                    );

                    if (answer) {
                        if (physical) {
                            this.product.isbn = result.data.newCheckDigit;
                        } else {
                            this.product.digitalIsbn =
                                result.data.newCheckDigit;
                        }
                    }
                }
            }
        },
        savePage(type){
            this.$emit('savePage',type);
        }
    }
};
</script>
