<template>
    <p-form ref="form">
        <div class="container-fluid content-wrapper" v-if="courseRequest">
            <b-row class="content-heading">
                <b-col>
                    <div>
                        {{ isNew ? 'Add' : isSingleRecord ? 'Edit' : 'Work' }}
                        Course Request
                    </div>
                </b-col>
                <b-col>
                    {{ isSingleRecord ? '' : `(${index + 1}/${ids.length})` }}
                </b-col>
                <b-col class="text-right">
                    <p-button
                        variant="danger"
                        class="mr-2"
                        hidden
                        :disabled="disableFields"
                        @click="deleteRequest"
                        >Delete</p-button
                    >
                    <p-button
                        variant="primary"
                        :is-busy="isBusy"
                        @click="saveSingleRecord"
                        :disabled="disableFields"
                        >Save</p-button
                    >
                </b-col>
            </b-row>
            <b-row class="pb-2" cols="2" cols-lg="12">
                <b-col class="d-flex mb-2">
                    <p-button
                        v-if="!isSingleRecord"
                        :is-busy="isBusy"
                        @click="previous"
                        :disabled="first"
                        variant="outline-primary"
                        >&#8592; Previous</p-button
                    >
                    <ProductMultiSelectQuickSearch
                        is-active-filter
                        exclude-used
                        include-products
                        show-buyers-guide
                        icon-plus
                        label="Add Product(s)"
                        name="itemSelectTop"
                        :course-request-filters="true"
                        @selections="addSelection"
                        fieldSet="courseRequestFields"
                        :disableAddButton="nullSKUitem"
                        class="ml-2 mr-2"
                        
                    />
                    <shelfTag
                        :disabled="isNew || formIsDirty || nullSKUitem"
                        :course-request="courseRequest"
                        filter-course-data
                        show-expand-all-option
                        @ok="setAsPrinted"
                    ></shelfTag>
                    <p-button
                        variant="outline-primary"
                        class="ml-2"
                        @click="createNewRequest"
                        v-if="isSingleRecord"
                        :disabled="nullSKUitem"
                        >Create New</p-button
                    >
                </b-col>
                <b-col cols="auto" class="ml-auto">
                    <p-button
                        v-if="!isSingleRecord"
                        :is-busy="isBusy"
                        @click="next"
                        variant="outline-primary"
                        class="mb-2 ml-2"
                        >{{
                            showDoneButton ? 'Done' : `Next &#8594;`
                        }}</p-button
                    >
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="6" xl="3">
                    <p-select
                        v-model="courseRequest.adoptionStatus"
                        :options="statusOptions"
                        label="Adoption Status"
                        text-field="value"
                        rules="required"
                        :disabled="disableFields || nullSKUitem"
                    />                   
                </b-col>
                <b-col cols="6" xl="9" v-if="nullSKUitem">
                    <div class="mt-4" style="color:red">
                        Items marked with an "Add" button, need to be completed before the status can be changed.
                    </div>
                </b-col>
            </b-row>
            <p-card>
                <b-row cols="1" cols-sm="2" cols-xl="4">
                    <b-col>
                        <p-advanced-select
                            :options="termOptions"
                            label="Term/Campus"
                            @selectedObject="termChange"
                            mode="lazy"
                            v-model="courseRequest.termId"
                            rules="required"
                            :disabled="disableFields"
                        />
                    </b-col>
                    <b-col>
                        <p-advanced-select
                            :options="departmentOptions"
                            label="Department"
                            v-model="courseRequest.courseDepartmentId"
                            no-options-message="No Departments Associated To Term/Campus"
                            rules="required"
                            :disabled="disableFields"
                        />
                    </b-col>
                    <b-col>
                        <p-select
                            data-type="courses"
                            :filter="this.courseRequest.courseDepartmentId"
                            empty-on-null-filter
                            label="Course"
                            mode="lazy"
                            v-model="courseRequest.courseId"
                            rules="required"
                            :disabled="disableFields"
                        >
                            <template v-slot:append>
                                <p-button
                                    class="ml-2 px-2"
                                    style="white-space:nowrap;"
                                    v-b-modal.addCourse
                                    :disabled="
                                        disableFields ||
                                            !courseRequest.courseDepartmentId
                                    "
                                    >Add New</p-button
                                >
                            </template>
                        </p-select>
                    </b-col>
                    <b-col>
                        <p-input
                            label="Section"
                            v-model="courseRequest.sectionName"
                            rules="required|max:80"
                            :disabled="disableFields"
                        />
                    </b-col>
                    <b-col>
                        <p-input
                            label="Section ID"
                            v-model="courseRequest.sectionId"
                            rules="max:20"
                            :disabled="disableFields"
                        />
                    </b-col>
                    <b-col>
                        <p-select
                            dataType="instructors"
                            label="Instructor"
                            v-model="courseRequest.instructorId"
                            mode="lazy"
                            rules="required"
                            :disabled="disableFields"
                        />
                    </b-col>
                    <b-col>
                        <p-select
                            dataType="courseLevels"
                            label="Course Level"
                            v-model="courseRequest.courseLevel"
                            :disabled="disableFields"
                        />
                    </b-col>
                    <b-col>
                       <b-row cols="1" cols-sm="2">
                        <b-col>
                            <p-number
                                input-type="integer"
                                left-align
                                label="Est. Enrollment"
                                v-model="courseRequest.estimatedEnrollment"
                                rules="min_value:0|integer"
                                :disabled="disableFields"
                            />
                        </b-col>
                        <b-col>
                            <p-number
                                input-type="integer"
                                left-align
                                label="Actual Enrollment"
                                v-model="courseRequest.actualEnrollment"
                                rules="min_value:0|integer"
                                :disabled="disableFields"
                            />
                        </b-col>
                      </b-row>
                    </b-col>
                </b-row>
                <b-row cols="1" cols-md="2">
                    <b-col>
                        <b-row>
                        <b-col>  
                        <p-text-area
                            label="Web Comment"
                            v-model="courseRequest.webComment"
                            rules="max:500"
                            rows="2"
                            :disabled="disableFields"
                        />
                        </b-col>
                        </b-row>
                        <b-row>
                        <b-col>    
                        <p-text-area
                            label="Shelf Tag Comment"
                            v-model="courseRequest.shelfTagComment"
                            rules="max:1000"
                            :disabled="disableFields"
                        />
                        </b-col>
                        <b-col cols="12" md="3">
                        <p-checkbox
                            label="Print Shelf Tag"
                            class="my-4"
                            v-model="courseRequest.printShelfTag"
                            :disabled="disableFields"
                            useSwitch
                        />
                        </b-col>
                        </b-row>
                    </b-col>
                    <b-col>
                        <p-text-area
                            label="Faculty Comments"
                            rows="7"
                            v-model="courseRequest.facultyComments"
                            :disabled="disableFields"
                        />
                    </b-col>
                </b-row>
            </p-card>
            <p-card>
                <b-row v-if="!disableFields">
                    <b-col cols="12" class="d-flex mb-3">
                            <h4 class="mt-2">Course Materials</h4>
                            <ProductMultiSelectQuickSearch
                            is-active-filter
                            exclude-used
                            include-products
                            show-buyers-guide
                            icon-plus
                            label="Add Product(s)"
                            name="itemSelect"
                            :course-request-filters="true"
                            @selections="addSelection"
                            class="ml-2"
                            :disableAddButton="nullSKUitem"
                            fieldSet="courseRequestFields"
                        />
                        <p-button
                            variant="outline-primary"
                            class="ml-2"
                            @click="checkDigital()"
                            :disabled="isNew || nullSKUitem"
                            >Check Digital</p-button
                        >
                        <p-button
                            variant="outline-danger"
                            class="ml-2"
                            @click="removeItems"
                            :disabled="this.$refs.bookTable == null || this.$refs.bookTable.selectedRows.length === 0"
                            >Delete</p-button
                        >
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12">
                        <p-table
                            :items="courseRequest.courseRequestProducts"
                            :fields="fields"
                            :current-page="currentPage"
                            :per-page="perPage"
                            ref="bookTable"                            
                        >
                       
                            <template v-slot:cell(sku)="{ value, item }">
                                <div v-if="value==null||value==''">
                                    <p-button size="sm" @click="addSKU(item)">
                                        Add
                                    </p-button>
                                </div>
                                <div v-else>
                                    {{value}}
                                </div>
                            </template>
                            <template v-slot:cell(date)="{ value, item }">
                                <p-datepicker
                                    v-model="item.requestDate"
                                    :disabled="disableFields"
                                    compact-format
                                    @date-picker-initialized="e => {if(item.courseRequestBookId){dataLoaded()}}"
                                    style="width: 113px;"
                                />
                            </template>
                            <template v-slot:cell(qty)="{ value, item }">
                                <p-number
                                    v-model="item.qty"
                                    name="Req Qty"
                                    input-type="integer"
                                    rules="min_value:0|max_value:99999"
                                    compact-format
                                    :disabled="disableFields"
                                />
                            </template>
                            <template v-slot:cell(courseRequestBookStatusId)="{ value, item }">
                                <p-select
                                    data-type="courseRequestBookStatuses"
                                    v-model="item.courseRequestBookStatusId"
                                    @input="printFlagtoTrue"
                                    compact-format
                                    :disabled="disableFields"
                                    rules="required"
                                    name="Req Type"
                                />
                            </template>
                            <template v-slot:cell(cancelled)="{ value, item }">
                                <p-checkbox
                                    v-model="item.isCancelled"
                                    size="md"
                                    :disabled="disableFields"
                                    @input="printFlagtoTrue"
                                />
                            </template>
                            <template
                                v-slot:cell(continuation)="{ value, item }"
                            >
                                <p-checkbox
                                    v-model="item.isContinuation"
                                    size="md"
                                    :disabled="disableFields"
                                />
                            </template>
                            <template
                                v-slot:cell(inclusiveAccess)="{ value, item }"
                            >
                                <p-checkbox
                                    v-model="item.inclusiveAccess"
                                    size="md"
                                />
                            </template>
                            <template
                                v-slot:cell(newOnly)="{ value, item }"
                            >
                                <p-checkbox
                                    v-model="item.isNewBookOnly"
                                    size="md"
                                />
                            </template>
                            <template
                                v-slot:cell(digitalIsbn)="{ value, item }"
                            >
                                <div
                                    v-if="
                                        item.digitalIsbn == null || item.digitalIsbn == '' ||
                                            item.bookVariant == null
                                    "
                                >
                                    <span>--</span>
                                </div>

                                <div
                                    v-else-if="
                                        item.digitalIsbn == 'Not Found' &&
                                            item.bookVariant != null
                                    "
                                >
                                    <span>Not Found</span>
                                </div>
                                <div v-else-if="checkAdoption(item) || item.bookVariant==3">
                                    <span>Adopted</span>
                                </div>
                                <p-button
                                    variant="outline-primary"
                                    class="ml-2"
                                    v-else-if="
                                        item.digitalIsbn != null &&
                                            item.digitalIsbn != 'Not Found'
                                    "
                                    @click="addDigitalBook(item)"
                                    v-b-modal.addDigitalBookToCourse
                                    >Add</p-button
                                >
                            </template>
                        </p-table>
                    </b-col>
                </b-row>
            </p-card>
            <b-row>
                <b-col class="mb-3 text-center">
                    <p-button
                        variant="primary"
                        :is-busy="isBusy"
                        @click="saveSingleRecord"
                        :disabled="disableFields"
                        >Save</p-button
                    >
                </b-col>
            </b-row>
        </div>
        <p-modal
            name="addDigitalBookToCourse"
            label="Add To Course Request"
            size="md"
            @ok="handleModalOk"
            ref="addDigitalBookToCourse"
            id="addDigitalBookToCourse"
        >
            <template v-slot:default>
                <p-form ref="digitalform">
                    <div>
                        <b-row cols="1">
                            <b-col>
                                <p-input
                                    label="Author"
                                    disabled="disabled"
                                    v-model="digitalBook.author"
                                />
                            </b-col>
                            <b-col>
                                <p-input
                                    label="Title"
                                    disabled="disabled"
                                    v-model="digitalBook.title"
                                />
                            </b-col>
                            <b-col>
                                <p-input
                                    label="Digital ISBN"
                                    disabled="disabled"
                                    v-model="digitalBook.digitalIsbn"
                                />
                            </b-col>
                        </b-row>
                    </div>
                </p-form>
            </template>
            <template v-slot:modal-footer="{ ok }">
                <b-row class="w-100">
                    <b-col class="mb-3 text-center">
                        <p-button
                            :is-busy="isBusy"
                            variant="primary"
                            @click="ok"
                            class="ml-2"
                            >Add to Course</p-button
                        >
                    </b-col>
                </b-row>
            </template>
        </p-modal>
        <p-modal
            name="addCourse"
            label="Add Course"
            size="md"
            @ok="handleAddCourse"
        >
            <course id="new" name="newCourse" ref="course" is-modal />
        </p-modal>
        <p-form ref="chooseSupplier">
            <p-modal
                name="bgSelectSupliers"
                label="Choose Supplier(s)"
                size="xl"
                no-close-on-backdrop
                no-close-esc
                ok-only
                ref="bgSelectSuplierModal"
                @ok.prevent="bgOk"
            >
                <b-row>
                    <b-col cols="12">
                        <p-table
                            :items="bgProducts"
                            :fields="bgAdoptFields"
                            :enable-row-selection="false"
                        >
                            <template v-slot:cell(supplierId)="{ value, item }">
                                <p-select
                                    data-type="suppliers"
                                    name="Supplier"
                                    v-model="item.supplierId"
                                    rules="required"
                                    mode="lazy"
                                />
                            </template>
                        </p-table>
                    </b-col>
                </b-row>
            </p-modal>
        </p-form>
        <p-modal
            size="sm"
            name="addSKUModal"
            :label="itemToAdd.isbn?'Create new or new and used?':'Create GM'"
            hide-footer
            no-close-on-backdrop
        >
            <p-form ref="addSKUForm">
            <div v-if="itemToAdd">
                <b-row v-if="itemToAdd.isbn">
                    <b-col>
                        <label>ISBN: </label>
                        <label class="ml-2" style="white-space: nowrap;">{{
                            itemToAdd.isbn
                        }}</label>
                    </b-col>
                </b-row>
                <b-row v-if="itemToAdd.isbn">
                    <b-col>
                        <label>Author: </label>
                        <label class="ml-2">{{ itemToAdd.author }}</label>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <label>Title/Description: </label>
                        <label class="ml-2">{{ itemToAdd.title }}</label>
                    </b-col>
                </b-row>
                <b-row v-if="itemToAdd.isbn">
                    <b-col>
                        <label>Edition: </label>
                        <label class="ml-2">{{ itemToAdd.edition }}</label>
                    </b-col>
                </b-row>
                <b-row v-if="itemToAdd.isbn">
                    <b-col>
                        <label>Binding: </label>
                        <label class="ml-2">{{ itemToAdd.binding }}</label>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <label>Price: </label>
                        <label class="ml-2">{{
                            currencyFormatter(itemToAdd.retail)
                        }}</label>
                    </b-col>
                </b-row>
                <b-row v-if="itemToAdd.isbn">
                    <b-col>
                        <label>Imprint: </label>
                        <label class="ml-2">{{ itemToAdd.imprint }}</label>
                    </b-col>
                </b-row>
                <b-row v-if="!itemToAdd.isbn">
                    <b-col>
                        <p-advanced-select
                            label="DCC"
                            data-type="dcc"
                            v-model.number="itemToAdd.dccId"
                            :filter="dccFilter"
                            mode="lazy"
                            rules="required"
                        />
                    </b-col>
                </b-row>
                <b-row v-if="itemToAdd.isbn">
                    <b-col>
                        <p-select
                            data-type="suppliers"
                            label="Supplier"
                            v-model="itemToAdd.supplierId"
                            mode="lazy"
                            rules="required"
                        />
                    </b-col>
                </b-row>
                <b-row v-if="itemToAdd.isbn">
                    <b-col>
                        <p-button
                            @click="newOnly"
                            :is-busy="isBusy"
                            variant="outline-primary"
                            >New</p-button
                        >
                    </b-col>
                    <b-col>
                        <p-button
                            @click="newAndUsed"
                            :is-busy="isBusy"
                            variant="outline-primary"
                            >New and Used</p-button
                        >
                    </b-col>
                </b-row>
                <b-row v-else>                    
                    <b-col class="text-right">
                        <p-button
                            @click="addGM"
                            :is-busy="isBusy"
                            variant="outline-primary"                            
                            >Generate SKU</p-button
                        >
                    </b-col>
                </b-row>
                
            </div>
            </p-form>
        </p-modal>
    </p-form>
</template>

<script>
import axios from 'axios';
import NavigationGuard from '@/components/mixins/NavigationGuard.js';
import selectListOptionsDataContext from '@/services/selectListOptions.dataContext.js';
import StoreListManager from '@/components/mixins/StoreListManager.js';
import ProductMultiSelectQuickSearch from '@/components/ProductMultiSelectQuickSearch.vue';
import course from '@/views/Courses/Edit.vue';
import unionWith from 'lodash/unionWith';
import remove from 'lodash/remove';
import {
    bookVariantFormatter,
    currencyFormatter
} from '../../components/Common/Formatters.js';
import shelfTag from '../../components/ShelfTag.vue';

export default {
    mixins: [NavigationGuard, StoreListManager],
    props: {
        id: String,
        ids: {
            type: [Array],
            default() {
                return [];
            }
        }
    },
    data() {
        return {
            selectListOptions: {
                courseRequestBookStatuses: []
            },
            courseRequest: null,
            index: 0,
            currentPage: 1,
            perPage: 25,
            isTermOpen: true,
            allTermOptions: [],
            allDepartmentOptions: [],
            courseDepartmentOptions: [],
            digitalBook: null,
            addedDigitalBooks: [],
            isBusy: false,
            bgProducts: null,
            addSelectedProducts: null,
            displayMessage: null,
            afterLoad: false,
            fields: [
                {
                    key: 'sku',
                    label: 'SKU',
                    automaticSearch: true
                },
                {
                    key: 'author',
                    label: 'Author',
                    automaticSearch: true,
                    hidden: true
                },
                {
                    key: 'title',
                    label: 'Title',
                    automaticSearch: true,
                    hidden: true
                },
                {
                    key: 'authorTitleOrDescription',
                    label: 'Description',
                    sortByFormatted: true,
                    automaticSearch: true,
                    sortable: true,
                    hidden: true
                },
                {
                    label: 'Author/Title or Description',
                    key: 'authorTitleOrDescription',
                    sortable: true
                },
                {
                    key: 'bookVariant',
                    formatter: (value, key, item) => {
                        return item.sku==null ? '' : bookVariantFormatter(value);
                    },
                    label: 'Type'
                },
                {
                    key: 'digitalIsbn',
                    label: 'Digital'
                },
                {
                    key: 'isbnCatalog',
                    label: 'Catalog # / ISBN',
                    tdClass: 'noWrap'
                },
                {
                    key: 'catalogNumber',
                    label: 'Catalog #',
                    automaticSearch: true,
                    hidden: true,
                    tdClass: 'noWrap'
                },
                {
                    key: 'isbn',
                    label: 'ISBN',
                    hidden: true,
                    automaticSearch: true,
                    tdClass: 'noWrap'
                },
                {
                    key: 'date',
                    label: 'Req. Date'
                },
                {
                    key: 'qty',
                    label: 'Req. Qty',
                    thClass: 'text-center',
                    tdClass: 'mw120'
                },
                {
                    key: 'courseRequestBookStatusId',
                    label: 'Req. Type'
                },
                {
                    key: 'inclusiveAccess',
                    label: 'IA/EA',
                    thClass: 'text-center',
                    tdClass: 'text-center'
                },
                {
                    key: 'newOnly',
                    thClass: 'text-center',
                    tdClass: 'text-center'
                },
                {
                    key: 'continuation',
                    thClass: 'text-center',
                    tdClass: 'text-center'
                },
                {
                    key: 'cancelled',
                    thClass: 'text-center',
                    tdClass: 'text-center'
                }
            ],
            bgAdoptFields: [
                {
                    key: 'description',
                    formatter: (value, key, item) => {
                        return value ? value : item.author + '/' + item.title;
                    },
                    sortByFormatted: true,
                    sortable: true
                },
                {
                    key: 'imprint',
                    label: 'Imprint'
                },
                {
                    key: 'isbn',
                    label: 'ISBN',
                    tdClass: 'noWrap',
                    sortable: true
                },
                {
                    key: 'retail',
                    label: 'Price',
                    sortable: true,
                    formatter: currencyFormatter
                },
                {
                    key: 'binding',
                    label: 'Binding',
                    sortable: true
                },
                {
                    key: 'supplierId',
                    label: 'Supplier*'
                }
            ],
            itemToAdd: {
                isbn:null
            }
        };
    },
    watch: {
        courseRequest: NavigationGuard.$watcher,
        //This watch is here because vue likes to re-use components.
        //When clicking 'Create New Request' while in a course request
        // we are reloading the same page, so in order to force the
        // component(s) to update we need to run load request.
        $route() {
            this.loadRequest();
        }
    },
    computed: {
        isNew() {
            return this.id === 'new';
        },
        isSingleRecord() {
            return this.id || this.ids.length == 1;
        },
        done() {
            return !this.isSingleRecord && this.index === this.ids.length;
        },
        showDoneButton() {
            return !this.isSingleRecord && this.index === this.ids.length - 1;
        },
        first() {
            return this.index === 0;
        },
        disableFields() {
            return (
                (this.courseRequest &&
                    this.courseRequest.adoptionStatus === 'Closed') 
                
            );
        },
        nullSKUitem(){
            return this.courseRequest.courseRequestProducts.some(x=>x.sku==null)
        },
        termOptions() {
            return this.isTermOpen
                ? this.allTermOptions.filter(x => x.filter === 'True')
                : this.allTermOptions;
        },
        departmentOptions() {
            return !this.disableFields
                ? this.courseDepartmentOptions
                : this.allDepartmentOptions;
        },
        bulkActions() {
            return this.isTermOpen
                ? [
                      {
                          label: 'Delete',
                          action: () => this.removeItems()
                      }
                  ]
                : [];
        },
        statusOptions() {
            return [
                { value: 'Open' },
                { value: 'Completed' },
                { value: 'Closed', disabled: this.isTermOpen },
                { value: 'Incoming', disabled: this.courseRequest.adoptionStatus=='Open' || this.courseRequest.adoptionStatus=='Completed' }
            ];
        }
    },
    methods: {
        currencyFormatter: currencyFormatter,
        checkAdoption(item) {
            let isAdopted = this.courseRequest.courseRequestProducts.some(
                x =>
                    item.digitalIsbn != null &&
                    x.digitalIsbn == item.digitalIsbn &&
                    x.sku != item.sku
            );

            return isAdopted;
        },
        addDigitalBook(item) {
            if (this.addedDigitalBooks.length == 0) {
                this.addedDigitalBooks.push({
                    digitalSku: item.digitalSku,
                    digitalIsbn: item.digitalIsbn,
                    physicalIsbn: item.isbn
                });
            }

            let selectedDigitalBook = this.addedDigitalBooks.find(x => x.digitalIsbn == item.digitalIsbn) ?? item;
            
            this.digitalBook = {
                ...item,
                sku: selectedDigitalBook.digitalSku,
                isbn: selectedDigitalBook.digitalIsbn,
                isbnCatalog: selectedDigitalBook.digitalIsbn,
                isPhysical: false,
                bookVariant: 3,
                courseRequestBookId: null,
                courseRequestBookStatusId: this.$store.getters.getCourseRequestBookStatusByName('Optional')?.value,
                qty: 0
            };
        },
        handleModalOk: async function() {
            this.courseRequest.courseRequestProducts = unionWith(
                this.courseRequest.courseRequestProducts,
                [this.digitalBook],
                (book, added) => {
                    return (
                        (book.sku === added.sku && added.sku !== 0) ||
                        (added.sku === 0 && book.isbn === added.isbn)
                    );
                }
            );
            this.printFlagtoTrue(true);
            this.$bvModal.hide('addDigitalBookToCourse');
        },
        checkDigital: async function() {
            let isbns = [];

            this.courseRequest.courseRequestProducts.forEach(x => {
                isbns.push(x.isbnDigits);
            });

            let response = await axios.post(
                'courserequests/checkdigital',
                isbns
            );

            this.addedDigitalBooks = response.data;            
            
            this.addedDigitalBooks.forEach(x => {
                this.courseRequest.courseRequestProducts
                    .filter(y => y.isbnDigits == x.physicalIsbn)
                    .map(z => (z.digitalIsbn = x.digitalIsbn));
            });
           
            
           
            this.courseRequest.courseRequestProducts.forEach(y => {
                if (y.digitalIsbn == null) {
                    y.digitalIsbn = 'Not Found';
                }
            });
           

            //We have to use set here so the digital field is properly updated.
            this.$set(
                this.courseRequest.courseRequestProducts,
                'courseRequestProducts',
                this.courseRequest.courseRequestProducts
            );

            this.loadRequest();
        },
        next: async function() {
            this.isBusy = true;
            try {
                if (this.done) {
                    this.$router.push('/courserequests');
                    return;
                }
                if (!this.disableFields) {
                    if (!(await this.$refs.form.validate())) {
                        return;
                    }
                    try {
                        await this.save();
                    } catch {
                        return;
                    }
                }
                this.index++;
                if (this.done) {
                    this.$router.push('/courserequests');
                    return;
                }
                await this.loadRequest();
            } finally {
                this.isBusy = false;
            }
        },
        previous: async function() {
            if (this.first) {
                return;
            }
            this.isBusy = true;
            try {
                if (!this.disableFields) {
                    if (!(await this.$refs.form.validate())) {
                        return;
                    }
                    try {
                        await this.save();
                    } catch {
                        return;
                    }
                }
                this.index--;
                await this.loadRequest();
            } finally {
                this.isBusy = false;
            }
        },
        loadRequest: function() {
            if (this.done) {
                return;
            }
            let id = this.ids.length > 0 ? this.ids[this.index] : this.id;

            let promise = this.isNew
                ? axios.get('courserequests/new')
                : axios.get('courserequests', { params: { id: id } });

            promise.then(async resp => {
                await this.termChange(
                    this.termOptions.find(t => t.value == resp.data.termId)
                );

                if (
                    (!this.isNew &&
                        resp.data &&
                        resp.data.termId &&
                        !this.allTermOptions.some(
                            t =>
                                t.value == resp.data.termId &&
                                t.filter === 'True'
                        )) ||
                    resp.data.adoptionStatus === 'Closed'
                ) {
                    this.isTermOpen = false;
                } else {
                    this.isTermOpen = true;
                }

                this.courseRequest = resp.data;

                if (!this.isNew) {
                    this.dataLoaded();
                }
                this.$refs.form.$refs.observer.reset();
            });
        },
        save: async function() {
            this.courseRequest.locationId = await selectListOptionsDataContext.getSelectedLocationIdAsync();
            let response = await axios.post(
                'courserequests',
                this.courseRequest
            );
            this.courseRequest = response.data;

            this.dataLoaded();
            if (this.isNew) {
                this.$router.push(
                    '/courserequests/' + response.data.courseRequestId
                );
            }
            this.$toasted.global
                .app_success(`Course request saved successfully.`)
                .goAway(5000);
        },
        printFlagtoTrue: function(event) {
            if (event) {
                this.courseRequest.printShelfTag = true;
            }
        },
        createNewRequest: async function() {
            if (!this.formIsDirty) {
                this.goToNewRequest();
            } else {
                this.$bvModal.msgBoxConfirm(this.displayMessage).then(value => {
                    if (value) {
                        this.dataSaved();
                        this.goToNewRequest();
                    }
                });
            }
        },
        goToNewRequest() {
            if (this.$route.path.indexOf('new') > -1) {
                this.$router.go();
            } else {
                this.$router.push('/courserequests/new');
            }
        },
        handleAddCourse: async function(event) {
            let newCourse = this.$refs.course;
            newCourse.course.courseDepartmentId = this.courseRequest.courseDepartmentId;

            //Calling validate on the form here doesn't do anything useful.
            //So in order to decide which way to go we will ensure both fields are valid.
            if (
                !newCourse.$refs.form.isFieldInvalid('courseName') &&
                !newCourse.$refs.form.isFieldInvalid('courseDescription')
            ) {
                this.courseRequest.courseId = (
                    await newCourse.onSubmit()
                ).courseId;
            } else {
                event.preventDefault();
                //call validate here so it triggers the red outline.
                newCourse.$refs.form.validate();
            }
        },
        termChange: async function(event) {
            if (
                event &&
                event.courseDepartmentIds &&
                event.courseDepartmentIds.length > 0
            ) {
                this.courseDepartmentOptions = (
                    await selectListOptionsDataContext.getStoreDropdownData(
                        'courseDepartments'
                    )
                ).filter(x => event.courseDepartmentIds.includes(x.value));
            } else {
                this.courseDepartmentOptions = [];
            }
        },
        saveSingleRecord: async function() {
            this.isBusy = true;
            if (!(await this.$refs.form.validate())) {
                this.isBusy = false;
                return;
            }
            try {
                await this.save();
            } catch {
                return;
            } finally {
                this.isBusy = false;
            }
        },
        selectSupplier(bgBooks) {
            this.bgProducts = bgBooks;
            this.$bvModal.show('bgSelectSupliers');
        },
        bgOk: async function(bvModalEvt) {
            bvModalEvt.preventDefault();
            if (!(await this.$refs.chooseSupplier.validate())) {
                return;
            }
            let addSelection = unionWith(
                this.bgProducts,
                this.addSelectedProducts,
                (book, added) => {
                    return (
                        (book.isbn === added.isbn && added.sku === 0) ||
                        (book.sku === 0 &&
                            !book.supplier &&
                            bookVariantFormatter(book.bookVariant) === 'GM')
                    );
                }
            );
            this.handleAddSelection(addSelection);
            this.$refs.bgSelectSuplierModal.hide();
        },
        addSelection: function(products) {
            let bgBooks = products.filter(
                x =>
                    x.sku === 0 ||
                    (!x.supplier &&
                        bookVariantFormatter(x.bookVariant) === 'GM')
            );
            if (bgBooks.length > 0) {
                let details = bgBooks.map(i => ({
                    supplierCost: 0,
                    ...i
                }));
                this.addSelectedProducts = products;
                this.selectSupplier(details);
                return;
            }

            this.handleAddSelection(products);
        },
        handleAddSelection(products) {
            let today = new Date();
            let details = products.map(i => ({
                qty: this.courseRequest.estimatedEnrollment,
                courseRequestBookStatusId:this.$store.getters.getCourseRequestBookStatusByName('Required')?.value,
                requestDate: today,
                ...i
            }));
            this.courseRequest.courseRequestProducts = unionWith(
                this.courseRequest.courseRequestProducts,
                details,
                (book, added) => {
                    return (
                        (book.sku === added.sku && added.sku !== 0) ||
                        (added.sku === 0 && book.isbn === added.isbn)
                    );
                }
            );
            this.printFlagtoTrue(true);
            if (!(this.$refs.form.validate())) {
                return;
            }else{
                this.save();
            }
            
        },
        deleteRequest: function() {
            this.$bvModal
                .msgBoxConfirm(
                    'Are you sure you want to delete this Course Request?',
                    {
                        okVariant: 'danger',
                        okTitle: 'Delete',
                        cancelTitle: 'Go Back'
                    }
                )
                .then(value => {
                    if (value) {
                        axios
                            .post('courserequests/delete', this.courseRequest)
                            .then(resp => {
                                if (resp) {
                                    this.dataSaved();
                                    this.$router.push('/courserequests');
                                }
                            });
                    }
                });
        },
        removeItems: function() {
            if (this.$refs.bookTable.selectedRows.length === 0) {
                this.$bvModal.msgBoxOk('Please select one or more products.');
                return;
            }

            let descriptions = this.$refs.bookTable.selectedRows
                .map(x => x.authorTitleOrDescription)
                .join('<br>');
            let preMessage = `Are you sure you want to remove the following products(s)? <br><br>${descriptions}`;
            let message = [
                        this.$createElement('div', { domProps: { innerHTML: preMessage } })
                    ];
                    
            this.$bvModal
                .msgBoxConfirm(
                    message,
                    {
                        okVariant: 'danger',
                        okTitle: 'Remove Product(s)',
                        cancelTitle: 'Go Back',
                        html: true
                    }
                )
                .then(value => {
                    if (value) {
                        let that = this;
                        remove(
                            this.courseRequest.courseRequestProducts,
                            function(item) {
                                return (
                                    that.$refs.bookTable.selectedRows.indexOf(
                                        item
                                    ) >= 0
                                );
                            }
                        );
                        this.courseRequest.courseRequestProducts = [
                            ...this.courseRequest.courseRequestProducts
                        ];
                        this.printFlagtoTrue(true);
                    }
                });
        },
        setAsPrinted() {
            this.loadRequest();
        },
        addSKU: function(item) {
            this.itemToAdd = item;
            this.$bvModal.show('addSKUModal');
            
        },
        newOnly() {
            this.addItem(true);
        },
        newAndUsed() {
            this.addItem(false);
        },
        addItem: async function(newOnly) {
            if (!(await this.$refs.addSKUForm.validate())) {
                return;
            }
            this.isBusy = true;
            this.itemToAdd.newOnly = newOnly;
            this.itemToAdd.IsCourseRequest = true; 
            this.itemToAdd.locationId = await selectListOptionsDataContext.getSelectedLocationIdAsync();
            axios
                .post('products/tx', this.itemToAdd)
                .then(() => {
                    this.$bvModal.hide('addSKUModal');
                    this.loadRequest();
                    this.$toasted.global
                        .app_success(
                            `Product '${this.itemToAdd.author} / ${this.itemToAdd.title}' created successfully.`
                        )
                        .goAway(5000);
                })
                .finally(() => {
                    this.isBusy = false;
                });
        },
        addGM: async function() {
            if (!(await this.$refs.addSKUForm.validate())) {
                return;
            }
            this.isBusy = true;
            this.itemToAdd.IsCourseRequest = true;            
            this.itemToAdd.locationId = await selectListOptionsDataContext.getSelectedLocationIdAsync();
            this.itemToAdd.description=this.itemToAdd.title;
            axios
                .post('products/generalmerchandise', this.itemToAdd)
                .then(() => {
                    this.$bvModal.hide('addSKUModal');
                    this.loadRequest();
                    this.$toasted.global
                        .app_success(
                            `Product '${this.itemToAdd.title}' created successfully.`
                        )
                        .goAway(5000);
                })
                .finally(() => {
                    this.isBusy = false;
                });
        },
        dccFilter(dcc) {
            return dcc.filter == 1;
        },
    },
    mounted: async function() {
        const h = this.$createElement;
        this.displayMessage = h('div', [
            h('p', ['Are you sure you want to leave without saving?']),
            h('p', [
                'Select ‘OK’ to discard changes or ‘Cancel’ to return to editing.'
            ])
        ]);
        if (this.id == null && (this.ids == null || this.ids.length == 0)) {
            this.$router.push('/courserequests');
        }
        this.allTermOptions = await selectListOptionsDataContext.getStoreDropdownData(
            'terms'
        );
        this.allDepartmentOptions = await selectListOptionsDataContext.getStoreDropdownData(
            'courseDepartments'
        );
        this.loadRequest();
    },
    components: {
        course,
        ProductMultiSelectQuickSearch,
        shelfTag
    }
};
</script>

<style scoped>
>>> select option[disabled] {
    display: none;
}

>>> .noWrap {
    white-space: nowrap;
}
</style>
