

<template>
    <div>

        <p-modal
            :button-label="label"
            :name="label"
            :disable-button="disableAddButton"
            size="xl"
            @show="initialize"
            @hidden='$emit("hidden",$event)'
            @ok="submitSelections"
            :ok-only="okOnly"
            :ok-title="okTitle"
            :label="label"
        >

        <p-search-table
            :fields="fields"
            sort-by="location"
            ref="apiSearch"
            :perPage="filters.perPage"
            api-destination="chartofaccounts"
            :filters="filters"
           @submit-selection="submitSelections"
           :items="items"
           v-model="selections"
           @per-page-change="e => {filters.perPage = e}"
        >
            <template v-slot:above-table>
                <b-row class="ml-2 mr-1 mt-2">
                    <b-col cols="12" sm="4" xl="2">
                        <p-select
                                label="Type"
                                data-type="chartOfAccountsTypes"
                                v-model="filters.chartOfAccountsTypeFilter"
                                addEmptyOption
                            />
                    </b-col>
                    <b-col cols="12" sm="4" xl="2">
                        <p-select
                            label="Category"
                            data-type="chartOfAccountsCategories"
                            v-model="filters.categoryFilter"
                            addEmptyOption
                        />
                    </b-col>
                    <b-col cols="12" sm="4" xl="2">
                        <p-select
                                label="Debit/Credit"
                                data-type="debitOrCreditOptions"
                                v-model="filters.debitCreditFilter"
                                addEmptyOption
                            />
                    </b-col>
                </b-row>
            </template>
        </p-search-table>
    </p-modal>
    </div>
</template>

<script>
import uniqueid from 'lodash.uniqueid';
import selectListOptionsDataContext from '@/services/selectListOptions.dataContext.js';

export default {
    props: {
        okOnly: {
            type: Boolean,
            default: false
        },
        okTitle: {
            type: String,
            default: "OK"
        },
        label: {
            type: String,
            default: 'Add Accounts'
        }, 
        name: {
            //name must be unique on the page, if it is not passed
            //in a unique name will be genearted for you.
            type: String,
            required: false,
            default() {
                return uniqueid('ChartQuickSearch_');
            }
        },        
        title: {
            type: String,
            default: null
        },
        disableAddButton: {
            type: Boolean,
            default: false
        },
        previousSelections: {
            type: Array,
            default: () => []
        },
        items: {
            type: Array,
            default: () => null
        }
    },
    data() {
        return {
            selections: [],
            filters: {
                automatic: null,
                sortBy: 'ledgerNumber',
                sortDesc: true,
                locationFilter: {},
                isActiveFilter:{},
                perPage: 10,
                chartOfAccountsTypeFilter: null,
                categoryFilter: null,
                debitCreditFilter: null,
                searchBy: 'All'
            },
            filterBy: [
                'All',
                'Ledger Number',
                'Description'
            ],
            fields: [
                {
                    key: 'ledgerNumber',
                    sortable: true,
                    label: 'Ledger Number'
                },
                {
                    key: 'chartOfAccountsType',
                    sortable: true,
                    label: 'Type'
                },
                {
                    key: 'category',
                    sortable: true,
                    label: 'Category'
                },
                {
                    key: 'debitOrCredit',
                    sortable: true,
                    label: 'Debit/Credit'
                },
                {
                    key: 'description',
                    sortable: true,
                    label: 'Description'
                },
                {
                    key: 'chartOfAccountsId',
                    hidden: true
                }
            ]
        };
    },
    methods:{
        async initialize() {            
            this.selections = [...this.previousSelections];            
        },
        submitSelections() {
            let emitValue = this.selections;

            this.$emit('selections', emitValue);
            this.$bvModal.hide(this.name);
        }
    },
    mounted: async function () {
        this.filters.locationFilter = await selectListOptionsDataContext.getSelectedLocationIdAsync();
        this.filters.isActiveFilter = true;
    }
};
</script>